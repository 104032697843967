<template>
  <div class="product-details">
    <form @submit.prevent="handleSubmit" v-if="!loadPage">
      <div
        class="d-flex align-items-enter action-buttons"
        :class="
          !isSpecialRoute ? 'justify-content-between' : 'justify-content-end'
        "
      >
        <div class="go-back" v-if="!isSpecialRoute">
          <router-link to="/wizard/review">
            <button class="back">Back</button>
          </router-link>
        </div>
        <div class="save-review d-flex align-items-enter">
          <button
            class="preview-btn"
            @click="onPreviewDetails"
            v-if="!isSpecialRoute"
          >
            Review
          </button>
          <div>
            <v-btn
              class="cursor-pointer save"
              :loading="loading"
              @click="handleSubmit"
              :disabled="!canSumbit"
              type="submit"
            >
              Save
            </v-btn>
          </div>
        </div>
      </div>
      <div
        class="header-details d-flex justify-content-between align-items-center"
        :class="editHeaderFixed ? 'header-details-fixed' : ''"
      >
        <div class="d-flex">
          <div class="image">
            <img :src="editProdcut.image" alt="" />
          </div>
          <div class="d-flex flex-column justify-content-between">
            <div class="title">{{ productName }}</div>
            <div class="description max-w-2xl">
              {{ productDescription }}
            </div>
          </div>
        </div>
      </div>
      <div class="upload d-flex justify-content-start align-items-center my-4">
        <div class="me-3">
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            class="mb-2"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 16C0 7.16344 7.16344 0 16 0H28C36.8366 0 44 7.16344 44 16V28C44 36.8366 36.8366 44 28 44H16C7.16344 44 0 36.8366 0 28V16Z"
              fill="#FAAD14"
            />
            <g clip-path="url(#clip0_2014_122420)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M27.2368 12.9778C27.2566 11.4884 25.6076 10.7858 24.4795 11.5935L20.3899 14.5216C20.3035 14.5835 20.2107 14.593 20.1449 14.5747L15.5124 13.2844C14.1801 12.9134 12.9131 14.1804 13.2842 15.5126L14.5744 20.1451C14.5928 20.211 14.5832 20.3037 14.5213 20.3902L11.5932 24.4798C10.7855 25.6078 11.4881 27.2569 12.9775 27.2371L17.8646 27.172C17.9438 27.1709 18.0001 27.2047 18.0319 27.2478L20.8548 31.0656C21.7333 32.2537 23.4766 31.7929 23.9381 30.5464L25.6683 25.8737C25.7028 25.7804 25.7801 25.703 25.8734 25.6685L30.5462 23.9384C31.7927 23.4769 32.2535 21.7335 31.0653 20.855L27.2476 18.0322C27.2045 18.0003 27.1707 17.944 27.1718 17.8648L27.2368 12.9778ZM25.3528 12.8131C25.4655 12.7323 25.567 12.7412 25.6322 12.7729C25.6667 12.7898 25.6919 12.8134 25.7081 12.8389C25.7229 12.8622 25.7377 12.8986 25.737 12.9578L25.6719 17.8449C25.6646 18.39 25.9112 18.9095 26.3558 19.2383L30.1735 22.0611C30.2296 22.1026 30.2644 22.1706 30.2445 22.2762C30.2241 22.3848 30.1498 22.4856 30.0253 22.5317L25.3526 24.2618C24.8489 24.4483 24.4481 24.8492 24.2616 25.3528L22.5315 30.0256C22.4854 30.1501 22.3846 30.2244 22.276 30.2448C22.1703 30.2646 22.1024 30.2299 22.0609 30.1738L19.238 26.356C18.9093 25.9114 18.3897 25.6649 17.8446 25.6721L12.9576 25.7372C12.8983 25.738 12.8619 25.7231 12.8387 25.7084C12.8131 25.6922 12.7895 25.667 12.7727 25.6324C12.741 25.5672 12.7321 25.4658 12.8128 25.353L15.741 21.2634C16.0531 20.8274 16.1659 20.2687 16.0194 19.7427L14.7292 15.1102C14.7044 15.021 14.7276 14.913 14.8202 14.8205C14.9128 14.7279 15.0208 14.7046 15.1099 14.7294L19.7424 16.0197C20.2684 16.1662 20.8271 16.0534 21.2631 15.7412L25.3528 12.8131Z"
                fill="white"
              />
              <path
                d="M29.0301 27.9697C28.7372 27.6768 28.2623 27.6768 27.9694 27.9697C27.6765 28.2626 27.6765 28.7374 27.9694 29.0303L31.4694 32.5303C31.7623 32.8232 32.2372 32.8232 32.5301 32.5303C32.823 32.2374 32.823 31.7626 32.5301 31.4697L29.0301 27.9697Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_2014_122420">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(10 10)"
                />
              </clipPath>
            </defs>
          </svg>
          <p><span>Upload Album</span></p>
        </div>
        <label class="input me-3 text-center" for="file-input">
          <input
            id="file-input"
            type="file"
            multiple
            @change="handleFileChange($event)"
            required
            style="position: absolute; left: -9999px"
          />
          <svg
            class="mx-auto mb-2"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.53125 0.375H6.46875C6.55208 0.375 6.59375 0.416667 6.59375 0.5V11.5C6.59375 11.5833 6.55208 11.625 6.46875 11.625H5.53125C5.44792 11.625 5.40625 11.5833 5.40625 11.5V0.5C5.40625 0.416667 5.44792 0.375 5.53125 0.375Z"
              fill="black"
              fill-opacity="0.45"
            />
            <path
              d="M0.75 5.40625H11.25C11.3333 5.40625 11.375 5.44792 11.375 5.53125V6.46875C11.375 6.55208 11.3333 6.59375 11.25 6.59375H0.75C0.666667 6.59375 0.625 6.55208 0.625 6.46875V5.53125C0.625 5.44792 0.666667 5.40625 0.75 5.40625Z"
              fill="black"
              fill-opacity="0.45"
            />
          </svg>
          <p>Upload</p>
        </label>

        <div
          class="previous-images"
          v-for="(image, imageIndex) in uploadedImages"
          :key="imageIndex"
        >
          <div class="image ml-3 position-relative">
            <div class="image-wraper overflow-hidden">
              <img :src="image.preview" alt="" />
              <div
                class="overlay position-absolute d-flex gap-2 justify-content-center align-items-center"
              >
                <svg
                  @click="onDeleteImage(imageIndex)"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7 7C7 7.55228 7.44772 8 8 8H16C16.5523 8 17 7.55228 17 7V6C17 3.23858 14.7614 1 12 1C9.23858 1 7 3.23858 7 6V7ZM9 6C9 4.34315 10.3431 3 12 3C13.6569 3 15 4.34315 15 6H9Z"
                    fill="  "
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M22 8H2V6H22V8Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4 7C4 6.44772 4.44772 6 5 6H19C19.5523 6 20 6.44772 20 7V16L20 16.0705C20 17.4247 20.0001 18.5413 19.8813 19.4251C19.7565 20.3529 19.4845 21.1723 18.8284 21.8284C18.1723 22.4845 17.3529 22.7565 16.4251 22.8812C15.5413 23.0001 14.4247 23 13.0706 23H13.0706H13.0705H13H11H10.9295H10.9294H10.9294C9.5753 23 8.45869 23.0001 7.57494 22.8812C6.64711 22.7565 5.82769 22.4845 5.17158 21.8284C4.51547 21.1723 4.2435 20.3529 4.11876 19.4251C3.99994 18.5413 3.99997 17.4247 4 16.0706V16.0706V16.0706L4 16V7ZM9 17V11H11V17H9ZM13 17V11H15V17H13Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section>
        <h5 class="">General Information</h5>
        <div class="row px-4">
          <div class="col-12 col-md-6">
            <div class="text-field" v-if="productGeneral.product_name">
              <div>
                <label
                  for="Product Name"
                  class="d-flex justify-content-start align-items-center gap-1"
                >
                  <span>Product Name</span>
                </label>
                <input
                  id="Product Name"
                  type="text"
                  v-model="productGeneral.product_name.value"
                  :class="productNameIsValid ? 'error' : ''"
                />
              </div>
              <div class="h-24px">
                <div class="invalid-feedback" v-if="productNameIsValid">
                  Please fill this field
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="text-field" v-if="productGeneral.sku">
              <div>
                <label
                  for="SKU"
                  class="d-flex justify-content-start align-items-center gap-1"
                >
                  <span>SKU</span>
                </label>
                <input
                  id="SKU"
                  type="text"
                  v-model="productGeneral.sku.value"
                  :class="skuIsValid ? 'error' : ''"
                />
              </div>
              <div class="h-24px">
                <div class="invalid-feedback" v-if="skuIsValid">
                  Please fill this field
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="text-field" v-if="productGeneral.brand_name">
              <div>
                <label
                  for="Brand Name"
                  class="d-flex justify-content-start align-items-center gap-1"
                >
                  <span>Brand Name</span>
                </label>
                <input
                  id="Brand Name"
                  type="text"
                  v-model="productGeneral.brand_name.value"
                  :class="brandNameIsValid ? 'error' : ''"
                />
              </div>
              <div class="h-24px">
                <div class="invalid-feedback" v-if="brandNameIsValid">
                  Please fill this field
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="text-field" v-if="productGeneral.barcode">
              <div>
                <label
                  for="Barcode"
                  class="d-flex justify-content-start align-items-center gap-1"
                >
                  <span>Barcode </span>
                </label>
                <input
                  id="Barcode"
                  type="text"
                  v-model="productGeneral.barcode.value"
                  :class="barcodeIsValid ? 'error' : ''"
                />
              </div>
              <div class="h-24px">
                <div class="invalid-feedback" v-if="barcodeIsValid">
                  Please fill this field
                </div>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="description" v-if="productGeneral.product_description">
              <label
                for="description"
                class="d-flex justify-content-start align-items-center gap-1"
              >
                <span>Product Description</span>
              </label>
              <ckeditor
                :editor="editor"
                v-model="productGeneral.product_description.value"
                @input="onEditorInput"
              ></ckeditor>
              <div class="invalid-feedback" v-if="descriptionValid">
                Please fill this filed
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="mt-4 bullet-point" v-if="productGeneral.bullet_point">
              <ul>
                <li
                  v-for="(item, index) in list"
                  :key="index"
                  class="d-flex gap-3 justify-content-between items-start my-1 rounded-lg p-3"
                >
                  <span>{{ item }}.</span>
                  <button @click="deleteItem(index)" class="delete">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7 7C7 7.55228 7.44772 8 8 8H16C16.5523 8 17 7.55228 17 7V6C17 3.23858 14.7614 1 12 1C9.23858 1 7 3.23858 7 6V7ZM9 6C9 4.34315 10.3431 3 12 3C13.6569 3 15 4.34315 15 6H9Z"
                        fill="white"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M22 8H2V6H22V8Z"
                        fill="white"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M4 7C4 6.44772 4.44772 6 5 6H19C19.5523 6 20 6.44772 20 7V16L20 16.0705C20 17.4247 20.0001 18.5413 19.8813 19.4251C19.7565 20.3529 19.4845 21.1723 18.8284 21.8284C18.1723 22.4845 17.3529 22.7565 16.4251 22.8812C15.5413 23.0001 14.4247 23 13.0706 23H13.0706H13.0705H13H11H10.9295H10.9294H10.9294C9.5753 23 8.45869 23.0001 7.57494 22.8812C6.64711 22.7565 5.82769 22.4845 5.17158 21.8284C4.51547 21.1723 4.2435 20.3529 4.11876 19.4251C3.99994 18.5413 3.99997 17.4247 4 16.0706V16.0706V16.0706L4 16V7ZM9 17V11H11V17H9ZM13 17V11H15V17H13Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </li>
              </ul>
              <div class="d-flex gap-3 mt-4">
                <input
                  id="bullet point"
                  type="text"
                  v-model="newItem"
                  placeholder="Add new point"
                  :class="bulletPointValid ? 'error' : ''"
                />
                <button @click="addItem" class="add"><span>+</span></button>
              </div>
              <div class="h-24px">
                <div class="invalid-feedback" v-if="bulletPointValid">
                  You are supposed to have at least one point
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <h5 class="">Product Details</h5>
        <div class="row">
          <div
            v-for="(detail, index) in productDetails"
            :key="detail.id"
            class="col-12 col-md-6"
          >
            <div class="string" v-if="detail.values.length == 0">
              <TextField
                :informations="detail"
                @inputEmit="handleTextFieldInputInDetails(detail, $event)"
              />
            </div>
            <div class="selector" v-else>
              <SelectInput
                :selectIndex="index"
                :initialValue="detail.value"
                :options="detail.values"
                :name="detail.name"
                @update:value="handleOptionChange"
              />
            </div>
          </div>
        </div>
      </section>
      <div class="min-w-40">
        <v-btn
          class="cursor-pointer save"
          :loading="loading"
          :disabled="!canSumbit"
          @click="handleSubmitS"
        >
          Save
        </v-btn>
      </div>
    </form>
    <div class="progress position-relative" v-else>
      <div>
        <div class="spinner-border custom-spinner" role="status">
          <span class="sr-only"></span>
        </div>
      </div>
    </div>
    <v-dialog v-model="isDataSavedDialog" max-width="520">
      <template v-slot:activator="{ props: activatorProps }">
        <v-btn v-bind="activatorProps" class="dialog-btn"></v-btn>
      </template>
      <v-card style="border-radius: 10px; padding: 20px 24px">
        <div class="d-flex align-items-center justify-content-center">
          <div>
            <div class="dialog-icon">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM11.0234 5.71406L7.73281 10.2766C7.68682 10.3408 7.62619 10.3931 7.55595 10.4291C7.48571 10.4652 7.40787 10.4841 7.32891 10.4841C7.24994 10.4841 7.17211 10.4652 7.10186 10.4291C7.03162 10.3931 6.97099 10.3408 6.925 10.2766L4.97656 7.57656C4.91719 7.49375 4.97656 7.37813 5.07812 7.37813H5.81094C5.97031 7.37813 6.12187 7.45469 6.21562 7.58594L7.32812 9.12969L9.78438 5.72344C9.87813 5.59375 10.0281 5.51562 10.1891 5.51562H10.9219C11.0234 5.51562 11.0828 5.63125 11.0234 5.71406Z"
                  fill="#52C41A"
                />
              </svg>
            </div>
            <div class="dialog-success-title">Data Saved Successfully</div>
            <div class="dialog-success-description">
              All changes have been saved successfully.
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SelectInput from "@/components/ui/SelectInput";
import TextField from "@/components/ui/TextField.vue";
import { updateProduct, getProductDetails } from "@/api/products";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-vue";
import { mapMutations } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "ProductsDetails",
  data() {
    return {
      productName: "",
      productDescription: "",
      selectBoxValue: null,
      changedValue: true,
      showDropDown: true,
      selectedPattern: null,
      uploadedImages: [],
      brandNameIsValid: false,
      barcodeIsValid: false,
      skuIsValid: false,
      productNameIsValid: false,
      bulletPointValid: false,
      descriptionValid: false,
      generalFormIsValid: false,
      loading: false,
      editor: ClassicEditor,
      list: [],
      newItem: "",
      dataGenerated: {},
      editProdcut: null,
      productGeneral: [],
      productDetails: [],
      isUserChangeData: false,
      isMounted: false,
      dataGeneratedFromLocalStorge: {},
      imageArr: [],
      loadPage: true,
      isDataSavedDialog: false,
      previewId: null,
    };
  },
  props: {
    details: Object,
    fromListing: Boolean,
    editHeaderFixed: Boolean,
    productId: null,
  },
  components: {
    SelectInput,
    TextField,
    ckeditor: CKEditor.component,
  },
  methods: {
    ...mapMutations([
      "setHideInfoCard",
      "setSsInHistoryPage",
      "setCurrentStep",
      "setListOfProducts",
      "setDataGenerated",
    ]),
    getProductDataById(id) {
      this.loading = true;
      this.loadPage = true;
      getProductDetails(id)
        .then((response) => {
          this.editProdcut = response.result;
          const images = JSON.parse(response.result.images);
          if (response.result.images) {
            this.uploadedImages = Object.keys(images).map((key) => {
              return {
                preview: images[key],
              };
            });
          }

          this.handelToFillData(this.editProdcut);
          this.loading = false;
          this.loadPage = false;
        })
        .catch((error) => {
          this.loading = false;
          this.loadPage = false;
        });
    },
    handleOptionChange(value) {
      this.productDetails[value.index].value = value.value;
    },
    selectValue(selection) {
      this.selectBoxValue = selection;
      this.changedValue = true;
    },
    handlePatternChange(value) {
      this.selectedPattern = value;
    },
    handleFileChange(event) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const imageObj = {
          file: file,
          preview: URL.createObjectURL(file),
        };
        this.uploadedImages.push(imageObj);
      }
    },
    onDeleteImage(imageIndex) {
      this.uploadedImages.splice(imageIndex, 1);
      this.imageArr.splice(imageIndex, 1);
    },

    handleInputInGeneral() {
      this.generalFormIsValid =
        !this.brandNameIsValid &&
        !this.barcodeIsValid &&
        !this.skuIsValid &&
        !this.productNameIsValid &&
        !this.descriptionValid;
    },
    handleTextFieldInputInDetails(fieldName, value) {
      fieldName.value = value;
    },
    handleSubmitS() {
      this.handleSubmit();
    },
    handleSubmit() {
      this.productGeneral.bullet_point.value = this.list.join(". ") + ".";
      this.loading = false;
      const id = this.editProdcut.id;

      const formData = new FormData();
      formData.append("general", JSON.stringify(this.productGeneral));
      formData.append("details", JSON.stringify(this.productDetails));
      this.uploadedImages
        .filter((imageObj) => imageObj.file instanceof File)
        .forEach((imageObj, index) => {
          formData.append(`images[${index}]`, imageObj.file);
        });
      this.uploadedImages
        .filter((imageObj) => !imageObj.preview.startsWith("blob:"))
        .forEach((imageObj, index) => {
          formData.append(`oldImage[${index}]`, imageObj.preview);
        });
      this.canSumbit = false;
      this.loading = true;

      updateProduct(id, formData)
        .then((data) => {
          this.isDataSavedDialog = true;
          let dataProducts = this.$store.state.dataGenerated;
          for (let i = 0; i < dataProducts.length; i++) {
            if (dataProducts[i].id == id) {
              dataProducts[i] = data.product;
              // localStorage.setItem(
              //   "respons-data-after-uplade-images",
              //   JSON.stringify(dataProducts)
              // );
              this.setListOfProducts(dataProducts);
              this.setDataGenerated(dataProducts);
            }
          }
          setTimeout(() => {
            this.isDataSavedDialog = false;
            this.loading = false;
          }, 2000);
        })
        .catch((error) => {
          this.canSumbit = true;
          this.loading = false;
        });
    },
    addItem() {
      if (this.newItem.trim() !== "") {
        this.list.push(this.newItem.trim());
        this.newItem = "";
      }
    },
    deleteItem(index) {
      this.list.splice(index, 1);
    },
    handelToFillData(product) {
      this.productGeneral = product.json_data.general;
      this.productDetails = product.json_data.details;
      this.productName = product.json_data.general.product_name.value;
      this.productDescription =
        product.json_data.general.product_description.value
          .split(" ")
          .slice(0, 25)
          .join(" ") + "...";
      this.list = product.json_data.general.bullet_point.value.split(".");
      this.list.pop();
    },
    onPreviewDetails() {
      console.log("go to preview");

      this.$router.push({
        name: "Preview Product",
        params: { id: this.previewId },
      });
    },
  },
  watch: {
    productId(newVal) {
      this.getProductDataById(newVal);
    },
    productDetails: {
      handler(newDetails) {
        if (this.isMounted) {
          this.isUserChangeData = true;
        }
      },
      deep: true,
    },
    productGeneral: {
      handler(newGeneralData) {
        if (this.isMounted) {
          this.isUserChangeData = true;
        }
      },
      deep: true,
    },
    uploadedImages: {
      handler(newImages) {
        if (this.isMounted) {
          this.isUserChangeData = true;
        }
      },
      deep: true,
    },
    isUserChangeData(newVal) {
      this.$emit("dataModification", newVal);
    },
    "productGeneral.product_name.value": function (newValue) {
      if (newValue.length == 0) {
        this.productNameIsValid = true;
      } else {
        this.productNameIsValid = false;
      }
    },
    "productGeneral.sku.value": function (newValue) {
      if (newValue.length == 0) {
        this.skuIsValid = true;
      } else {
        this.skuIsValid = false;
      }
    },
    "productGeneral.brand_name.value": function (newValue) {
      if (newValue.length == 0) {
        this.brandNameIsValid = true;
      } else {
        this.brandNameIsValid = false;
      }
    },
    "productGeneral.barcode.value": function (newValue) {
      if (newValue.length == 0) {
        this.barcodeIsValid = true;
      } else {
        this.barcodeIsValid = false;
      }
    },
    "productGeneral.product_description.value": function (newValue) {
      if (newValue.length == 0) {
        this.descriptionValid = true;
      } else {
        this.descriptionValid = false;
      }
    },
    list: {
      handler(newList) {
        if (newList.length == 0) {
          this.bulletPointValid = true;
        } else {
          this.bulletPointValid = false;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.setHideInfoCard(false);
    this.setSsInHistoryPage(true);
    this.setCurrentStep(2);

    this.isMounted = true;
    if (this.$route.params.id) {
      this.getProductDataById(this.$route.params.id);
      this.previewId = this.$route.params.id;
    } else {
      if (this.productId) {
        this.getProductDataById(this.productId);
        this.previewId = this.productId;
      }
    }
  },
  unmounted() {
    this.setSsInHistoryPage(false);
  },
  computed: {
    // ...mapGetters(["dataGenerated"]),
    generalJsondata() {
      if (this.editProdcut.json_data) {
        return this.editProdcut.json_data.general;
      }
    },
    detailsJsontData() {
      if (this.editProdcut.json_data) {
        return this.editProdcut.json_data.details;
      }
    },
    canSumbit() {
      return (
        !this.brandNameIsValid &&
        !this.barcodeIsValid &&
        !this.skuIsValid &&
        !this.productNameIsValid &&
        !this.bulletPointValid &&
        !this.descriptionValid
      );
    },
    isSpecialRoute() {
      return (
        this.$route.path.includes("/wizard/listing") ||
        this.$route.path.includes("/history")
      );
    },
  },
};
</script>

<style scoped lang="scss">
.product-details {
  background: #fff;
  padding: 24px;
  text-align: left;
  min-height: 75vh;
  overflow-x: scroll;
  scrollbar-width: none;
  .action-buttons {
    margin-bottom: 24px;
    button.back,
    button.preview-btn {
      padding: 8px 16px;
      font-size: 16px;
      color: #000000e0;
      border: $main-border-1px;
      border-radius: $main-border-radius;
      border: 1px solid var(--Button-colorBorder, #00000026);
      transition: all 0.1s;
      &:hover {
        color: #2c80ff;
        background: #f3feff;
      }
    }
  }
}
.header-details {
  padding: 16px 0;
}
.header-details-fixed {
  position: fixed;
  top: 64px;
  background: #fff;
  min-height: 86px;
  width: calc(100vw - 328px);
  left: 280px;
  z-index: 99;
  border: 1px solid rgba(0, 0, 0, 0.059);
  padding: 10px 20px;
  border-radius: 10px;
}
.image {
  width: 48px;
  height: 48px;
  overflow: hidden;
  margin-right: 16px;
  img {
    width: 100%;
    height: auto;
  }
}
.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #263238;
}
.description {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #00000073;
  label {
    color: #000000e0;
    margin-bottom: 8px;
  }
}
button {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 8px 16px;
}
.save {
  --v-theme-overlay-multiplier: var(--v-theme-primary-overlay-multiplier);
  background-color: #2c80ff;
  color: white;
  padding: 8px 16px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0px 2px 0px 0px #0591ff1a;
  border-top: 1px solid #2c80ff;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-left: 12px;
  border-radius: $main-border-radius;
}
.upload {
  overflow-x: scroll;
  border-radius: $main-border-radius;
  border: 1px;
  background: #f5f5f5;
  border: 1px dashed #f0f0f0;
  padding: 24px;
  color: #000000e0;
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    span {
      font-weight: 600;
    }
  }
  .input {
    width: 104px;
    height: 104px;
    padding: 30px 12px 30px 12px;
    border-radius: 8px;
    background: #0000000a;
    border: 1px dashed #d9d9d9;
    cursor: pointer;
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-align: center;
    }
  }
}
.previous-images .image {
  width: 104px;
  height: 104px;
  border: 1px solid #d9d9d9;
  border-radius: $main-border-radius;
  .image-wraper {
    width: 88px;
    height: 88px;
    margin-top: 8px;
    border-radius: $main-border-radius;
    margin-left: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: filter 0.3s;
    img {
      width: 100%;
      height: auto;
    }
    .overlay {
      background: #00000073;
      width: 88px;
      height: 88px;
      border-radius: $main-border-radius;
      opacity: 0;
      transition: opacity 0.3s;
      svg {
        cursor: pointer;
      }
    }
  }
  &:hover {
    .overlay {
      opacity: 1;
    }
  }
}
section {
  padding: 20px;
  background: #00000005;
  margin-top: 24px;
  border-radius: $main-border-radius;
  h5 {
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #263238;
  }
}
.text-field {
  label {
    margin-bottom: 8px;
  }
  input {
    padding: 5px 12px;
    border-radius: 6px;
    border: 1px solid #00000026;
    outline: none;
    width: 100%;
    background: #ffffff;
    font-weight: 600;
  }
}
input.error {
  border: 1px solid red;
  padding: 5px 12px;
  border-radius: 6px;
  outline: none;
  width: 100%;
}
.bullet-point {
  input {
    padding: 5px 12px;
    border-radius: 6px;
    border: 1px solid #00000026;
    outline: none;
    width: 100%;
  }
  ul {
    padding-left: 0;
    li {
      border: 1px solid rgba(128, 128, 128, 0.132);
      transition: 0.3s all;
      &:hover {
        background: #bae0ff86;
      }
    }
  }
  button {
    &.add {
      background-color: #2c80ff;
      color: white;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      box-shadow: 0px 2px 0px 0px #0591ff1a;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-size: 24px;
      }
    }
    &.delete {
      background: transparent;
      padding: 0;
      svg path {
        fill: red;
      }
    }
  }
}
.h-24px {
  height: 24px;
}
</style>
