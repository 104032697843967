<template>
  <router-view />
</template>
<script>
import { mapGetters } from "vuex";
import EventBus from "./event-bus";

export default {
  computed: {
    ...mapGetters(["user"]),
  },
  created() {
    // if (this.user) {
    //   window.Echo.channel('requests-channel' + this.user.id)
    //     .listen('.request-updated', (e) => {
    //       console.log('Received message:', e);
    //     });
    // } else {
    //   console.log('User is not defined');
    // }
  },
};
</script>