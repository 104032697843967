<template>
  <div class="product">
    <div class="d-flex gap-4 p-3">
      <div
        class="image"
        :class="product.status == 'PROCESSING' ? 'loading-animation' : ''"
      >
        <img :src="url" alt="" />
      </div>
      <div class="details d-flex flex-column">
        <h5 v-if="name && name.general">
          {{ getName(name.general.product_name.value) }}
        </h5>
        <p class="description" v-if="name && name.general.product_description">
          {{ getDescription(name.general.product_description.value) }}
        </p>
        <div v-if="product.status == 'FAILED'">
          <div class="status d-flex gap-2 align-items-center p-3">
            <svg
              width="16"
              height="22"
              viewBox="0 0 16 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 4C4.13438 4 1 7.13438 1 11C1 14.8656 4.13438 18 8 18C11.8656 18 15 14.8656 15 11C15 7.13438 11.8656 4 8 4ZM10.5844 13.6594L9.55313 13.6547L8 11.8031L6.44844 13.6531L5.41563 13.6578C5.34688 13.6578 5.29063 13.6031 5.29063 13.5328C5.29063 13.5031 5.30156 13.475 5.32031 13.4516L7.35313 11.0297L5.32031 8.60938C5.30143 8.58647 5.29096 8.5578 5.29063 8.52812C5.29063 8.45937 5.34688 8.40312 5.41563 8.40312L6.44844 8.40781L8 10.2594L9.55156 8.40938L10.5828 8.40469C10.6516 8.40469 10.7078 8.45937 10.7078 8.52969C10.7078 8.55937 10.6969 8.5875 10.6781 8.61094L8.64844 11.0312L10.6797 13.4531C10.6984 13.4766 10.7094 13.5047 10.7094 13.5344C10.7094 13.6031 10.6531 13.6594 10.5844 13.6594Z"
                fill="#FF4D4F"
              />
            </svg>
            <p class="capitalize">Generation Failed</p>
          </div>
          <div class="actions d-flex mt-2">
            <button class="blue-btn" @click="regenerate(id)">
              Regenerate
              <svg
                v-if="loading"
                xmlns="http://www.w3.org/2000/svg"
                style="margin: auto; background: none; display: block"
                width="20px"
                height="20px"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
              >
                <circle
                  cx="50"
                  cy="50"
                  fill="none"
                  stroke="#000"
                  stroke-width="10"
                  r="35"
                  stroke-dasharray="164.93361431346415 56.97787143782138"
                >
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    repeatCount="indefinite"
                    dur="1s"
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                  ></animateTransform>
                </circle>
              </svg>
            </button>
          </div>
        </div>
        <div
          v-if="product.status != 'PROCESSING' && product.status != 'FAILED'"
        >
          <!-- <div v-if="true"> -->
          <div
            class="status w-fit d-flex gap-2 align-items-center p-3 cursor-pointer"
            @click="onDisplayStatus(id)"
          >
            <svg
              width="16"
              height="22"
              viewBox="0 0 16 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 4C4.13438 4 1 7.13438 1 11C1 14.8656 4.13438 18 8 18C11.8656 18 15 14.8656 15 11C15 7.13438 11.8656 4 8 4ZM11.0234 8.71406L7.73281 13.2766C7.68682 13.3408 7.62619 13.3931 7.55595 13.4291C7.48571 13.4652 7.40787 13.4841 7.32891 13.4841C7.24994 13.4841 7.17211 13.4652 7.10186 13.4291C7.03162 13.3931 6.97099 13.3408 6.925 13.2766L4.97656 10.5766C4.91719 10.4938 4.97656 10.3781 5.07812 10.3781H5.81094C5.97031 10.3781 6.12187 10.4547 6.21562 10.5859L7.32812 12.1297L9.78438 8.72344C9.87813 8.59375 10.0281 8.51562 10.1891 8.51562H10.9219C11.0234 8.51562 11.0828 8.63125 11.0234 8.71406Z"
                fill="#52C41A"
              />
            </svg>

            <p class="capitalize" style="font-size: 12px">
              Image Genrate Success
            </p>
          </div>
          <div class="actions d-flex">
            <router-link
              :to="{ name: 'Edit Product', params: { id: product.id } }"
            >
              <button class="blue-btn">Edit</button></router-link
            >
            <svg
              width="16"
              height="22"
              viewBox="0 0 16 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8 0V22" stroke="black" stroke-opacity="0.06" />
            </svg>
            <router-link :to="{ name: 'Preview Product', params: { id: id } }">
              <button class="blue-btn">Preview</button>
            </router-link>
            <svg
              width="16"
              height="22"
              viewBox="0 0 16 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8 0V22" stroke="black" stroke-opacity="0.06" />
            </svg>
            <button class="cancel-btn" @click="cancelProduct">Delete</button>
          </div>
        </div>
        <div v-if="product.status == 'PROCESSING'">
          <div
            @click="onDisplayStatus(id)"
            class="status w-fit d-flex gap-2 align-items-center position-relative cursor-pointer"
          >
            <v-progress-circular
              class=""
              :size="16"
              :width="1"
              color="primary"
              indeterminate
            ></v-progress-circular>
            <p>Image In Processing</p>
          </div>
          <div class="actions">
            <!--            <button class="cancel-btn" @click="cancelProduct(id)">-->
            <!--              Cancel-->
            <!--            </button>-->
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="isDataSavedDialog" max-width="520">
      <template v-slot:activator="{ props: activatorProps }">
        <v-btn v-bind="activatorProps" class="dialog-btn"></v-btn>
      </template>
      <v-card style="border-radius: 10px; padding: 20px 24px">
        <div class="d-flex align-items-center justify-content-center">
          <div>
            <div class="dialog-icon">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM11.0234 5.71406L7.73281 10.2766C7.68682 10.3408 7.62619 10.3931 7.55595 10.4291C7.48571 10.4652 7.40787 10.4841 7.32891 10.4841C7.24994 10.4841 7.17211 10.4652 7.10186 10.4291C7.03162 10.3931 6.97099 10.3408 6.925 10.2766L4.97656 7.57656C4.91719 7.49375 4.97656 7.37813 5.07812 7.37813H5.81094C5.97031 7.37813 6.12187 7.45469 6.21562 7.58594L7.32812 9.12969L9.78438 5.72344C9.87813 5.59375 10.0281 5.51562 10.1891 5.51562H10.9219C11.0234 5.51562 11.0828 5.63125 11.0234 5.71406Z"
                  fill="#52C41A"
                />
              </svg>
            </div>
            <div class="dialog-success-title">Successfully Regenerated</div>
            <div class="dialog-success-description">
              Regenerate request have been sent successfully.
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { regenerateProduct } from "@/api/products";

export default {
  name: "ProductComp",
  data() {
    return {
      productName: "",
      productDescription: "",
      loading: false,
      isDataSavedDialog: false,
    };
  },
  props: {
    id: Number,
    name: String,
    url: String,
    product: null,
  },
  methods: {
    getName(name) {
      if (name) {
        const words = name.split(" ");
        const truncatedName = words.slice(0, 4).join(" ");
        const finalName =
          words.length > 3 ? truncatedName + "..." : truncatedName;
        return finalName;
      }
    },
    getDescription(description) {
      if (description) {
        const words = description.split(" ");
        const truncatedDescription = words.slice(0, 10).join(" ");
        const finalDescription =
          words.length > 5
            ? truncatedDescription + "..."
            : truncatedDescription;
        return finalDescription;
      }
    },
    cancelProduct(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to remove this product from the list?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$emit("onDeleteProduct", id);
        }
      });
    },
    regenerate(id) {
      this.loading = true;
      regenerateProduct(id)
        .then((response) => {
          this.loading = false;
          if (response.message) {
            this.isDataSavedDialog = true;
          }
          let dataProducts = this.$store.state.dataGenerated;
          for (let i = 0; i < dataProducts.length; i++) {
            if (dataProducts[i].id == id) {
              dataProducts[i] = response.result;
              this.setListOfProducts(dataProducts);
              this.setDataGenerated(dataProducts);
              // localStorage.setItem(
              //   "respons-data-after-uplade-images",
              //   JSON.stringify(dataProducts)
              // );
              this.loading = false;
            }
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
  mounted() {
    if (this.name) {
      this.productName = this.name.general.product_name.value;
    }
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.product {
  margin-bottom: 24px;
  & > div {
    height: 200px;
    box-shadow: 0px 2px 0px 0px #0591ff1a;
    border: 1px solid #0000000f;
    border-radius: $main-border-radius;
    .image {
      width: 152px;
      height: 152px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        object-fit: cover;
        border-radius: 6px;
      }
    }
  }
  .details {
    h5 {
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      height: 22px;
      overflow: hidden;
    }
    .description {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: #00000073;
      margin-top: 8px;
      height: 44px;
      overflow: hidden;
    }
    .status {
      box-shadow: 0px 2px 5px 1px #bfbfbf4a;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      padding: 9px 12px;
      height: 44px;
      width: fit-content;
      margin-top: 8px;
    }
    .actions {
      margin-top: 8px;
      button {
        background: transparent;
      }
    }
  }
}
@media (max-width: 576px) {
  .product > div {
    flex-direction: column;
  }
}

.loading-animation {
  position: relative;
  overflow: hidden;
}
.loading-animation::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 55%;
  background: linear-gradient(to right, transparent, #ffffff8f, transparent);
  transform: skewX(-20deg);
  animation: loading 1.5s infinite;
}
@keyframes loading {
  0% {
    transform: translateX(-100%) skewX(-20deg);
  }
  100% {
    transform: translateX(200%) skewX(-20deg);
  }
}
</style>
