<template>
  <div class="product-status">
    <div class="icon">
      <svg
        class="mx-auto"
        width="185"
        height="152"
        viewBox="0 0 185 152"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_3434_18638)">
          <path
            d="M92.297 151.228C129.74 151.228 160.094 145.556 160.094 138.56C160.094 131.563 129.74 125.892 92.297 125.892C54.8537 125.892 24.5 131.563 24.5 138.56C24.5 145.556 54.8537 151.228 92.297 151.228Z"
            fill="#AEB8C2"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M146.534 101.344L122.609 71.8988C121.461 70.5128 119.783 69.6738 118.016 69.6738H66.5761C64.8101 69.6738 63.1321 70.5128 61.9841 71.8988L38.0601 101.344V114.727H146.535V101.344H146.534Z"
            fill="#AEB8C2"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M58.3301 31.6699H126.263C127.324 31.6699 128.341 32.0913 129.092 32.8415C129.842 33.5916 130.263 34.6091 130.263 35.6699V129.014C130.263 130.075 129.842 131.092 129.092 131.842C128.341 132.592 127.324 133.014 126.263 133.014H58.3301C57.2692 133.014 56.2518 132.592 55.5017 131.842C54.7515 131.092 54.3301 130.075 54.3301 129.014V35.6699C54.3301 34.6091 54.7515 33.5916 55.5017 32.8415C56.2518 32.0913 57.2692 31.6699 58.3301 31.6699Z"
            fill="#F5F5F5"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M67.1781 41.623H117.415C117.946 41.623 118.454 41.8338 118.829 42.2088C119.204 42.5839 119.415 43.0926 119.415 43.623V68.58C119.415 69.1105 119.204 69.6192 118.829 69.9943C118.454 70.3693 117.946 70.58 117.415 70.58H67.1781C66.6476 70.58 66.1389 70.3693 65.7639 69.9943C65.3888 69.6192 65.1781 69.1105 65.1781 68.58V43.623C65.1781 43.0926 65.3888 42.5839 65.7639 42.2088C66.1389 41.8338 66.6476 41.623 67.1781 41.623ZM67.4401 81.437H117.153C117.753 81.437 118.328 81.6754 118.753 82.0996C119.177 82.5238 119.415 83.0991 119.415 83.699C119.415 84.299 119.177 84.8743 118.753 85.2985C118.328 85.7227 117.753 85.961 117.153 85.961H67.4401C66.8402 85.961 66.2648 85.7227 65.8406 85.2985C65.4164 84.8743 65.1781 84.299 65.1781 83.699C65.1781 83.0991 65.4164 82.5238 65.8406 82.0996C66.2648 81.6754 66.8402 81.437 67.4401 81.437ZM67.4401 93.2H117.153C117.753 93.2 118.329 93.4384 118.753 93.8627C119.177 94.287 119.416 94.8625 119.416 95.4625C119.416 96.0626 119.177 96.6381 118.753 97.0624C118.329 97.4867 117.753 97.725 117.153 97.725H67.4401C66.84 97.725 66.2646 97.4867 65.8403 97.0624C65.416 96.6381 65.1776 96.0626 65.1776 95.4625C65.1776 94.8625 65.416 94.287 65.8403 93.8627C66.2646 93.4384 66.84 93.2 67.4401 93.2ZM146.313 136.702C145.538 139.773 142.816 142.062 139.578 142.062H45.0151C41.7771 142.062 39.0551 139.772 38.2811 136.702C38.1335 136.117 38.0589 135.516 38.0591 134.912V101.345H64.3771C67.2841 101.345 69.6271 103.793 69.6271 106.765V106.805C69.6271 109.776 71.9971 112.175 74.9041 112.175H109.689C112.596 112.175 114.966 109.754 114.966 106.782V106.77C114.966 103.798 117.309 101.344 120.216 101.344H146.534V134.913C146.534 135.53 146.457 136.129 146.313 136.702Z"
            fill="#DCE0E6"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M149.621 33.292L142.791 35.942C142.616 36.0102 142.424 36.0275 142.24 35.992C142.055 35.9564 141.884 35.8693 141.746 35.7408C141.608 35.6123 141.51 35.4476 141.462 35.2656C141.414 35.0836 141.418 34.8917 141.474 34.712L143.411 28.505C140.822 25.561 139.302 21.971 139.302 18.097C139.302 8.102 149.42 0 161.902 0C174.381 0 184.5 8.102 184.5 18.097C184.5 28.092 174.382 36.194 161.901 36.194C157.373 36.194 153.157 35.128 149.621 33.292Z"
            fill="#F5F5F5"
          />
          <path
            d="M170.804 21.3654C172.377 21.3654 173.653 20.105 173.653 18.5504C173.653 16.9957 172.377 15.7354 170.804 15.7354C169.23 15.7354 167.955 16.9957 167.955 18.5504C167.955 20.105 169.23 21.3654 170.804 21.3654Z"
            fill="#AEB8C2"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M155.848 21.0129H150.15L153.048 16.0869L155.848 21.0129ZM159.409 16.0869H164.394V21.0129H159.409V16.0869Z"
            fill="#AEB8C2"
          />
        </g>
        <defs>
          <clipPath id="clip0_3434_18638">
            <rect
              width="184"
              height="152"
              fill="white"
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
    <div class="product-status_description">
      <p class="w-auto">
        Innovating technology through groundbreaking research.
      </p>
    </div>
    <div class="">
      <div
        v-if="productStatus == 'success'"
        class="status d-flex gap-2 align-items-center cursor-pointer mx-auto"
      >
        <svg
          width="16"
          height="22"
          viewBox="0 0 16 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 4C4.13438 4 1 7.13438 1 11C1 14.8656 4.13438 18 8 18C11.8656 18 15 14.8656 15 11C15 7.13438 11.8656 4 8 4ZM11.0234 8.71406L7.73281 13.2766C7.68682 13.3408 7.62619 13.3931 7.55595 13.4291C7.48571 13.4652 7.40787 13.4841 7.32891 13.4841C7.24994 13.4841 7.17211 13.4652 7.10186 13.4291C7.03162 13.3931 6.97099 13.3408 6.925 13.2766L4.97656 10.5766C4.91719 10.4938 4.97656 10.3781 5.07812 10.3781H5.81094C5.97031 10.3781 6.12187 10.4547 6.21562 10.5859L7.32812 12.1297L9.78438 8.72344C9.87813 8.59375 10.0281 8.51562 10.1891 8.51562H10.9219C11.0234 8.51562 11.0828 8.63125 11.0234 8.71406Z"
            fill="#52C41A"
          />
        </svg>

        <p class="capitalize">Image Genrate Success</p>
      </div>
      <div
        v-else-if="productStatus == 'failed'"
        class="status w-fit d-flex gap-2 align-items-center cursor-pointer mx-auto"
      >
        <svg
          width="16"
          height="22"
          viewBox="0 0 16 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 4C4.13438 4 1 7.13438 1 11C1 14.8656 4.13438 18 8 18C11.8656 18 15 14.8656 15 11C15 7.13438 11.8656 4 8 4ZM10.5844 13.6594L9.55313 13.6547L8 11.8031L6.44844 13.6531L5.41563 13.6578C5.34688 13.6578 5.29063 13.6031 5.29063 13.5328C5.29063 13.5031 5.30156 13.475 5.32031 13.4516L7.35313 11.0297L5.32031 8.60938C5.30143 8.58647 5.29096 8.5578 5.29063 8.52812C5.29063 8.45937 5.34688 8.40312 5.41563 8.40312L6.44844 8.40781L8 10.2594L9.55156 8.40938L10.5828 8.40469C10.6516 8.40469 10.7078 8.45937 10.7078 8.52969C10.7078 8.55937 10.6969 8.5875 10.6781 8.61094L8.64844 11.0312L10.6797 13.4531C10.6984 13.4766 10.7094 13.5047 10.7094 13.5344C10.7094 13.6031 10.6531 13.6594 10.5844 13.6594Z"
            fill="#FF4D4F"
          />
        </svg>

        <p class="capitalize">Generation Failed</p>
      </div>
      <div
        v-else
        class="status w-fit d-flex gap-2 align-items-center position-relative cursor-pointer mx-auto"
      >
        <v-progress-circular
          class=""
          :size="16"
          :width="1"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <p>Processing Image</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductStatus",
  components: {},
  props: {
    productId: Number,
    productStatus: null,
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.status {
  box-shadow: 0px 2px 5px 1px #bfbfbf4a;
  background: #fff;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding: 9px 12px;
  margin-top: 10px;
  width: fit-content;
  p {
    color: #000000e0;
  }
}
.product-status {
  padding-top: 16vh;
  padding-bottom: 10vh;
  text-align: center;
  background: #fff;
  min-height: 72vh;
}
.product-status_description {
  p {
    color: #00000073;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    margin-top: 12px;
  }
}
</style>
