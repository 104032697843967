import { createStore } from "vuex";
import axios from "axios";
import router from "@/router";
export default createStore({
  state: {
    currentStep: 0,
    hideInfoCard: true,
    hideUserDataCard: false,
    currentPage: "stepper",
    userData: {
      user: localStorage.getItem("user")
        ? localStorage.getItem("user") != "undefined"
          ? JSON.parse(localStorage.getItem("user"))
          : ""
        : null,
      token: localStorage.getItem("accessToken")
        ? localStorage.getItem("accessToken") != "undefined"
          ? localStorage.getItem("accessToken")
          : ""
        : null,
      loggedIn: !!localStorage.getItem("accessToken"),
      locale: !!localStorage.getItem("locale"),
    },
    userName: null,
    filterHistory: null,
    category: "",
    categoryModalStatus: false,
    data: [
      {
        index: {
          category: "",
          imgaes: [],
        },
      },
    ],
    dataGenerated: [],
    isInPreview: false,
    hidePagePath: false,
    generateBtnIsActive: false,
    listOfProducts: [],
    isListOfProductsDisabled: false,
  },
  getters: {
    currentStep: (state) => state.currentStep,
    hideInfoCard: (state) => state.hideInfoCard,
    hideUserDataCard: (state) => state.hideUserDataCard,
    category: (state) => state.category,
    isAuthenticated: (state) => state.loggedIn,
    dataGenerated: (state) => state.dataGenerated,
    user: (state) => state.userData.user,
    userName: (state) => state.userName,
    isInPreview: (state) => state.isInPreview,
    hidePagePath: (state) => state.hidePagePath,
    currentPage: (state) => state.currentPage,
    listOfProducts: (state) => state.listOfProducts,
    isListOfProductsDisabled: (state) => state.isListOfProductsDisabled,
    locale: (state) => state.locale,
  },
  mutations: {
    incrementStep(state) {
      if (state.currentStep < 3) {
        // state.currentStep++;
      }
    },
    decrementStep(state) {
      if (state.currentStep >= 1) {
        // state.currentStep--;
      }
    },
    setCurrentStep(state, newValue) {
      state.currentStep = newValue;
    },
    setStepValue(state, newValue) {
      state.currentStep = newValue;
    },
    setHideInfoCard(state, newValue) {
      state.hideInfoCard = newValue;
    },

    setCategory(state, category) {
      state.category = category;
    },
    updateCategory(state, newValue) {
      localStorage.setItem("stepNO", 1);
      state.category = newValue;
    },
    setCategoryModalStatus(state, value) {
      state.categoryModalStatus = value;
    },
    toggleCategoryModal(state) {
      state.categoryModalStatus = !state.categoryModalStatus;
    },
    setUser(state, { user, token }) {
      state.userData.user = user;
      state.userData.token = token;
      state.userData.loggedIn = true; // Update loggedIn state
    },
    clearUser(state) {
      state.userData.user = null;
      state.userData.token = null;
      state.userData.loggedIn = false;
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      router.push("/login");
    },
    setToken(state, token) {
      state.token = token;
    },
    setDataGenerated(state, data) {
      state.dataGenerated = data;
    },
    setIsInPreview(state, newValue) {
      state.isInPreview = newValue;
    },
    setHidePagePath(state, newValue) {
      state.hidePagePath = newValue;
    },
    setHideUserDataCard(state, newValue) {
      state.hideUserDataCard = newValue;
    },
    setFilterHistory(state, filter) {
      state.filterHistory = filter;
    },
    setCurrentPage(state, text) {
      state.currentPage = text;
    },
    setGenerateBtn(state, active) {
      state.generateBtnIsActive = active;
    },
    setListOfProducts(state, products) {
      state.listOfProducts = products;
    },
    setIsListOfProductsDisabled(state, products) {
      state.isListOfProductsDisabled = products;
    },
    setLocale(state, locale) {
      localStorage.setItem("locale", locale);
      state.locale = locale;
    },
    setUserName(state, name) {
      state.userName = name;
    },
  },
  actions: {
    nextStep({ commit }) {
      commit("incrementStep");
    },
    backStep({ commit }) {
      commit("decrementStep");
    },
    // loginUser({ commit }, userData) {
    //   axios
    //     .post("/login", userData)
    //     .then((response) => {
    //       commit("clearErrorMessage");
    //       commit("setToken", response.data.accessToken);
    //       localStorage.setItem("accessToken", response.data.accessToken);
    //       router.push("/");
    //     })
    //     .catch((error) => {
    //       console.error("Login failed:", error);
    //       commit("setErrorMessage", error.message);
    //       throw error;
    //     });
    // },
    logoutUser({ commit }) {
      commit("clearUser");
    },
    uploadImages({ commit }) {
      console.log("UploadImages action called");
    },
    filterHistory({ commit }, filter) {
      commit("setFilterHistory", filter);
    },
    generateBtn({ commit }, active) {
      commit("setGenerateBtn", active);
    },
    setLocale({ commit }, locale) {
      commit("Setlocale", locale);
    },
  },
  modules: {},
  plugins: [],
});
