<template>
  <div>
    <div class="selector mb-6">
      <label
        :for="name"
        class="d-flex justify-content-start align-items-center gap-1"
      >
        <span>{{ name }}</span>
      </label>
      <div
        class="selectBox input d-flex-fill"
        :class="showDropDown ? `show` : ``"
        @click="showDropDown = !showDropDown"
        :style="!displayedData.length ? 'border-color:#dc3545' : ''"
      >
        <div class="selectBox__value">
          {{
            !displayedData.length
              ? "Select Option"
              : typeof displayedData == "object"
              ? displayedData.join(", ")
              : displayedData
          }}
        </div>
        <div class="dropdown-menu">
          <li
            v-for="(option, index) in options"
            :key="index"
            class="dropdown-item small-text"
            @click="selectValue(option, index)"
            :class="{ checked: isValueSelected(option.value) }"
          >
            {{ option.value }}
          </li>
        </div>
      </div>
    </div>
    <div class="invalid-feedback" v-if="isEmpty">Please fill this filed</div>
  </div>
</template>

<script>
export default {
  name: "ProductsDetails",

  props: {
    options: Array,
    initialValue: {
      type: [String, Array, Object],
      default: null,
    },
    name: String,
    selectIndex: Number,
  },
  data() {
    return {
      selectBoxValue: [],
      displayedData: [],
      changedValue: true,
      showDropDown: false,
      isEmpty: false,
      checked: null,
    };
  },
  watch: {
    initialValue: {
      immediate: true,
      handler(newValue) {
        this.updateDisplayedData(newValue);
      },
    },
  },
  methods: {
    selectValue(option, index) {
      const isSelected = this.selectBoxValue.some(
        (item) => item.key === option.key
      );
      const isValueIncluded = this.displayedData.includes(option.value);

      if (!isSelected && !isValueIncluded) {
        this.selectBoxValue.push({
          key: option.key,
          value: option.value,
        });
        this.displayedData.push(option.value);
        this.checked = index;
        this.changedValue = true;
        this.$emit("update:value", {
          index: this.selectIndex,
          value: this.selectBoxValue,
        });
      } else {
        const selectedIndex = this.selectBoxValue.findIndex(
          (item) => item.key === option.key
        );

        this.selectBoxValue.splice(selectedIndex, 1);
        this.$emit("update:value", {
          index: this.selectIndex,
          value: this.selectBoxValue,
        });

        const displayedDataIndex = this.displayedData.findIndex(
          (item) => item === option.value
        );
        if (displayedDataIndex !== -1) {
          this.displayedData.splice(displayedDataIndex, 1);
        }
        this.changedValue = true;
      }
    },

    isValueSelected(value) {
      return this.displayedData.includes(value);
    },
    updateDisplayedData(newVal) {
      this.selectBoxValue = [];
      this.displayedData = [];
      if (Array.isArray(newVal) && newVal.length > 0) {
        this.selectBoxValue = newVal.map((obj) => ({
          key: obj.key,
          value: obj.value,
        }));
        this.displayedData = newVal.map((obj) => obj.value);
      } else if (newVal && newVal.key && newVal.value) {
        this.selectBoxValue.push({
          key: newVal.key,
          value: newVal.value,
        });
        this.displayedData.push(newVal.value);
      }
    },
  },
  mounted() {
    this.updateDisplayedData(this.initialValue);
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.selector {
  label {
    margin-bottom: 8px;
  }
}
.selectBox {
  background-color: #fbfbfb;
  border: 1px solid #00000026;
  position: relative;
  padding: 5px 12px;
  cursor: pointer;
  height: 32px;
  border-radius: 6px;
  &:after {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%) rotate(0deg);
    transition: all 0.2s ease-in-out;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.001' height='8.165' viewBox='0 0 14.001 8.165'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23212121;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M13.861,60.224l-.7-.7a.441.441,0,0,0-.645,0L7,65.036,1.487,59.522a.441.441,0,0,0-.645,0l-.7.7a.441.441,0,0,0,0,.645l6.537,6.538a.441.441,0,0,0,.645,0l6.538-6.538a.442.442,0,0,0,0-.645Z' transform='translate(0 -59.382)'/%3E%3C/svg%3E");
  }
  .dropdown-menu {
    position: absolute;
    opacity: 0;
    display: block;
    top: 32px;
    left: 0;
    width: 100%;
    z-index: -1;
    overflow-y: auto;
    transform: translateY(-15%);
    visibility: hidden;
    display: none;
    border-radius: 4px;
    border: 1px solid #d3d7df;
    background: #fff;
  }
  &.show .dropdown-menu {
    transition: all 0.3s ease-in-out;
    visibility: visible;
    display: block;
    opacity: 1;
    z-index: 1;
    transform: translateY(0);
    .dropdown-item {
      color: #000000e0;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      padding: 4px 12px;
      border-radius: 6px;
      transition: all 0.3s ease-in-out;
      list-style-type: none;
      &.checked {
        background: #bae0ff;
        font-weight: bold;
      }
      &:hover,
      &.active {
        background-color: #edf0f8;
        color: #333132;
      }
    }
  }
}
.selectBox__value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  color: #000000e0;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}
#check {
  font-size: 14px;
}
// @media (max-width: 576px) {
//   .entities .selectBox {
//     width: 100%;
//     margin-bottom: 16px;
//   }
// }
</style>
