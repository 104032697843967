<template>
  <div>
    <div class="title">
      <h4>{{ $t("Select Category") }}</h4>
      <p class="mb-4">
        {{ $t("category_second_title") }}
      </p>
    </div>
    <div class="position-relative" v-if="categoriesLength === 0">
      <SelectCategoriesSkeleton />
    </div>
    <div v-else class="categories pt-4">
      <div class="row g-4">
        <div
          v-for="category in categories"
          :key="category.id"
          class="col-lg-4 col-md-6 col-sm-12"
        >
          <div
            class="category text-center cursor-pointer"
            @click="selectItem(category.name)"
            :class="{ selected: selectedItem === category.name }"
          >
            <div class="image">
              <img
                v-if="category.image"
                :src="category.image"
                :alt="category.name"
              />
              <img
                v-else
                src="../../assets/images/shirt 1.png"
                :alt="category.name"
              />
            </div>
            <div class="titel-description">
              <h5>{{ category.name }}</h5>
              <p v-if="selectedItem == category.name">
                {{ category.name }} {{ $t("Category selected") }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="next-back d-flex justify-content-between">
        <NormalButton
          @click="nextStep"
          :disabled="!nextBtnIsActive"
          class="ml-auto"
          >Next</NormalButton
        >
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import NormalButton from "@/components/ui/NormalBtn.vue";
import SelectCategoriesSkeleton from "../ui/skeletons/SelectCategoriesSkeleton.vue";
export default {
  name: "categoriesComp",
  data() {
    return {
      nextBtnIsActive: false,
    };
  },
  props: {
    selectedItem: String,
    categories: Array,
  },
  components: {
    NormalButton,
    SelectCategoriesSkeleton,
  },
  methods: {
    selectItem(index) {
      this.$emit("selectItem", index);
      this.$store.commit("updateCategory", index);
      this.nextBtnIsActive = true;
    },
    nextStep() {
      store.dispatch("nextStep");
      this.$router.push({ path: "/wizard/upload-images" });
    },
    backStep() {
      store.dispatch("backStep");
    },
  },
  computed: {
    categoriesData() {
      return this.categories;
    },
    categoriesLength() {
      if (this.categories) {
        return this.categories.length;
      }
      return 0;
    },
  },
};
</script>
<style scoped lang="scss">
.title {
  border-bottom: 1px solid var(--Colors-Neutral-Border-colorSplit, #0000000f);
  h4 {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: #000000e0;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: var(--Colors-Neutral-Text-colorTextSecondary, #000000a6);
    width: 90%;
  }
}
.row > div {
  height: 186px;
}
.category {
  border: 1px solid var(--Colors-Neutral-Border-colorBorderSecondary, #f0f0f0);
  border-radius: $main-border-radius;
  padding: 16px 24px;
  transition: all 0.2s;
  &:hover {
    border: 1px solid #c9fafe;
    background: #f4fbfb;
  }
  &.selected {
    background: #f3feff;
    border: 1px solid #4096ff;
  }
  .image {
    width: 70px;
    height: 70px;
    overflow: hidden;
    margin: 16px auto;
    img {
      width: 100%;
      height: auto;
    }
  }
  .titel-description {
    h5 {
      margin-bottom: 4px;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: #000000e0;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: var(--Colors-Neutral-Text-colorTextSecondary, #000000a6);
    }
  }
}
.next-back {
  margin-top: 24px;
}
</style>
