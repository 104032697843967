<template>
  <div class="row">
    <div class="col-lg-4 col-md-6 col-sm-12 p-3" v-for="(card, index) in 6" :key="index">
      <div class="skeleton-loader">
        <div class="skeleton-image loading-animation"></div>
        <div class="skeleton-title loading-animation"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.skeleton-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  border: 1px solid #cccccc44;
  border-radius: 8px;
  overflow: hidden;
  padding: 20px;
}

.skeleton-image,
.skeleton-title {
  position: relative;
  overflow: hidden;
}

.skeleton-image {
  width: 70px;
  height: 70px;
  background-color: #eae7e7;
  border-radius: 20px;
}

.skeleton-title {
  width: 60%;
  height: 20px;
  background-color: #eae7e7;
  border-radius: 4px;
}

.loading-animation::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  background: linear-gradient(to right, transparent, #ffffff8f, transparent);
  transform: skewX(-20deg);
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    transform: translateX(-100%) skewX(-20deg);
  }
  100% {
    transform: translateX(200%) skewX(-20deg);
  }
}
</style>
