<template>
  <div class="sidebar">
    <div class="h-100 d-flex flex-column">
      <ul>
        <li>
          <button
            :class="'my-profile' == activeBtn ? 'active' : ''"
            @click="goToMyProfile()"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_3147_150765)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.00016 1.74992C6.09636 1.74992 5.25016 2.59751 5.25016 3.79159C5.25016 4.98566 6.09636 5.83325 7.00016 5.83325C7.90397 5.83325 8.75016 4.98566 8.75016 3.79159C8.75016 2.59751 7.90397 1.74992 7.00016 1.74992ZM4.0835 3.79159C4.0835 2.08616 5.32664 0.583252 7.00016 0.583252C8.67369 0.583252 9.91683 2.08616 9.91683 3.79159C9.91683 5.49701 8.67369 6.99992 7.00016 6.99992C5.32664 6.99992 4.0835 5.49701 4.0835 3.79159Z"
                  fill="#000"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.99183 10.3443C2.02004 8.97334 3.63371 8.1665 5.34738 8.1665H8.65294C10.3666 8.1665 11.9803 8.97334 13.0085 10.3443L13.0377 10.3832C13.2838 10.7113 13.4168 11.1105 13.4168 11.5207C13.4168 12.5677 12.568 13.4165 11.521 13.4165H2.47933C1.43229 13.4165 0.583496 12.5677 0.583496 11.5207C0.583496 11.1105 0.716542 10.7113 0.962663 10.3832L0.99183 10.3443ZM5.34738 9.33317C4.00092 9.33317 2.73304 9.96711 1.92516 11.0443L1.896 11.0832C1.80133 11.2094 1.75016 11.3629 1.75016 11.5207C1.75016 11.9234 2.07662 12.2498 2.47933 12.2498H11.521C11.9237 12.2498 12.2502 11.9234 12.2502 11.5207C12.2502 11.3629 12.199 11.2094 12.1043 11.0832L12.0752 11.0443C11.2673 9.96711 9.9994 9.33317 8.65294 9.33317H5.34738Z"
                  fill="#000"
                />
              </g>
              <defs>
                <clipPath id="clip0_3147_150765">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span>{{ $t("my profile") }}</span>
          </button>
        </li>
        <li>
          <button
            :class="'my-shop' == activeBtn ? 'active' : ''"
            @click="goToMyShopIntegration"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_3147_144608)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.583497 8.20918L0.583497 5.79049C0.58348 4.72681 0.583468 3.86945 0.674126 3.19514C0.76825 2.49506 0.969611 1.9056 1.43777 1.43744C1.90593 0.969283 2.49538 0.767921 3.19547 0.673797C3.86978 0.583139 4.72715 0.583152 5.79083 0.583169H8.2095C9.27318 0.583152 10.1305 0.583139 10.8049 0.673797C11.5049 0.767921 12.0944 0.969283 12.5626 1.43744C13.0307 1.9056 13.2321 2.49506 13.3262 3.19514C13.4169 3.86945 13.4168 4.72682 13.4168 5.7905V8.20917C13.4168 9.27285 13.4169 10.1302 13.3262 10.8045C13.2321 11.5046 13.0307 12.0941 12.5626 12.5622C12.0944 13.0304 11.5049 13.2318 10.8049 13.3259C10.1306 13.4165 9.27319 13.4165 8.20951 13.4165H5.79082C4.72714 13.4165 3.86978 13.4165 3.19547 13.3259C2.49538 13.2318 1.90593 13.0304 1.43777 12.5622C0.969611 12.0941 0.76825 11.5046 0.674126 10.8045C0.583468 10.1302 0.58348 9.27286 0.583497 8.20918ZM1.83039 10.6491C1.90712 11.2198 2.04747 11.522 2.26273 11.7373C2.47799 11.9525 2.78021 12.0929 3.35092 12.1696C3.93842 12.2486 4.71706 12.2498 5.8335 12.2498H8.16683C9.28327 12.2498 10.0619 12.2486 10.6494 12.1696C11.2201 12.0929 11.5223 11.9525 11.7376 11.7373C11.9529 11.522 12.0932 11.2198 12.1699 10.6491C12.2489 10.0616 12.2502 9.28294 12.2502 8.1665V5.83317C12.2502 4.71673 12.2489 3.93809 12.1699 3.3506C12.0932 2.77988 11.9529 2.47766 11.7376 2.2624C11.5223 2.04714 11.2201 1.90679 10.6494 1.83006C10.0619 1.75107 9.28327 1.74984 8.16683 1.74984H5.8335C4.71706 1.74984 3.93842 1.75107 3.35092 1.83006C2.78021 1.90679 2.47799 2.04714 2.26273 2.2624C2.04747 2.47766 1.90712 2.77988 1.83039 3.3506C1.7514 3.93809 1.75016 4.71673 1.75016 5.83317L1.75016 8.1665C1.75016 9.28294 1.7514 10.0616 1.83039 10.6491Z"
                  fill="black"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.25033 11.0832H2.91699V9.9165H5.25033V11.0832Z"
                  fill="black"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.66701 4.44051V1.1665H5.83368V4.44051L6.63141 4.17459C6.63655 4.17288 6.64248 4.17084 6.64914 4.16855C6.71477 4.14599 6.85163 4.09895 7.00035 4.09895C7.14907 4.09895 7.28592 4.14599 7.35155 4.16855C7.35822 4.17084 7.36415 4.17288 7.36928 4.17459L8.16701 4.44051V1.1665H9.33368V4.44051C9.33368 4.45007 9.33368 4.45971 9.33369 4.46942C9.33377 4.62807 9.33386 4.80495 9.31237 4.95154C9.28756 5.12068 9.21763 5.37674 8.9703 5.55501C8.72297 5.73327 8.45793 5.71864 8.28963 5.68869C8.14377 5.66272 7.97599 5.6067 7.82551 5.55646C7.8163 5.55338 7.80715 5.55033 7.79808 5.5473L7.00035 5.28139L6.20261 5.5473C6.19354 5.55033 6.18439 5.55338 6.17518 5.55646C6.0247 5.6067 5.85692 5.66272 5.71106 5.68869C5.54276 5.71864 5.27772 5.73327 5.03039 5.55501C4.78306 5.37674 4.71313 5.12068 4.68833 4.95154C4.66683 4.80495 4.66692 4.62807 4.667 4.46942C4.66701 4.45971 4.66701 4.45007 4.66701 4.44051ZM7.07396 5.25761C7.07399 5.2576 7.07399 5.2576 7.07396 5.25761L7.07396 5.25761ZM6.92674 5.25761C6.92671 5.2576 6.9267 5.2576 6.92673 5.25761L6.92674 5.25761ZM8.15685 4.78921C8.15684 4.7892 8.15693 4.78871 8.15715 4.78777L8.15685 4.78921ZM8.49958 4.54096C8.50054 4.54105 8.50104 4.54112 8.50104 4.54113L8.49958 4.54096ZM5.49965 4.54113C5.49966 4.54112 5.50016 4.54105 5.50111 4.54096L5.49965 4.54113ZM5.84355 4.78777C5.84376 4.78871 5.84386 4.7892 5.84385 4.78921L5.84355 4.78777Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_3147_144608">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span>{{ $t("my_shop") }}</span>
          </button>
        </li>
        <li>
          <button :class="' my-paln' === activeBtn ? ' active' : ''">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.00016 2.33325C7.18855 2.33325 7.36534 2.42423 7.47484 2.57753L10.0335 6.15959L12.4691 4.21108C12.6442 4.071 12.8841 4.04369 13.0862 4.14083C13.2883 4.23796 13.4168 4.44235 13.4168 4.66659V6.45925C13.4168 7.52294 13.4169 8.3803 13.3262 9.05461C13.2321 9.7547 13.0307 10.3442 12.5626 10.8123C12.0944 11.2805 11.5049 11.4818 10.8049 11.576C10.1306 11.6666 9.27319 11.6666 8.20951 11.6666H5.79082C4.72714 11.6666 3.86978 11.6666 3.19547 11.576C2.49538 11.4818 1.90593 11.2805 1.43777 10.8123C0.969611 10.3442 0.76825 9.7547 0.674126 9.05461C0.583468 8.38031 0.58348 7.52295 0.583497 6.45927L0.583497 4.66659C0.583497 4.44235 0.712032 4.23796 0.914137 4.14083C1.11624 4.04369 1.35614 4.071 1.53124 4.21108L3.96687 6.15959L6.52549 2.57753C6.63498 2.42423 6.81178 2.33325 7.00016 2.33325ZM7.00016 3.92019L4.55818 7.33897C4.46505 7.46935 4.32239 7.55569 4.16369 7.57771C4.00498 7.59974 3.84421 7.55552 3.71909 7.45543L1.75016 5.88028V6.41659C1.75016 7.53302 1.7514 8.31166 1.83039 8.89916C1.90712 9.46987 2.04747 9.7721 2.26273 9.98736C2.47799 10.2026 2.78021 10.343 3.35092 10.4197C3.93842 10.4987 4.71706 10.4999 5.8335 10.4999H8.16683C9.28327 10.4999 10.0619 10.4987 10.6494 10.4197C11.2201 10.343 11.5223 10.2026 11.7376 9.98736C11.9529 9.7721 12.0932 9.46987 12.1699 8.89916C12.2489 8.31166 12.2502 7.53302 12.2502 6.41659V5.88028L10.2812 7.45543C10.1561 7.55552 9.99535 7.59974 9.83664 7.57771C9.67794 7.55569 9.53528 7.46935 9.44215 7.33897L7.00016 3.92019Z"
                fill="black"
              />
            </svg>
            <span>{{ $t("my_plan") }}</span>
          </button>
          <span class="soon">soon</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "historySidebar",
  data() {
    return {
      activeBtn: "my-profile",
    };
  },
  components: {},
  methods: {
    goToMyProfile() {
      this.$router.push({ path: "/setting/my-profile" });
      this.activeBtn = "my-profile";
    },
    goToMyShopIntegration() {
      this.$router.push({ path: "/my-shop" });
      this.activeBtn = "my-shop";
    },
  },
  watch: {
    $route(to) {
      if (to.path === "/setting/my-profile") {
        this.activeBtn = "my-profile";
      } else if (to.path === "/my-shop") {
        this.activeBtn = "my-shop";
      }
      localStorage.setItem("activeBtn", this.activeBtn);
    },
  },
  created() {
    const savedActiveBtn = localStorage.getItem("activeBtn");
    if (savedActiveBtn) {
      this.activeBtn = savedActiveBtn;
    }

    if (this.$route.path === "/setting/my-profile") {
      this.activeBtn = "my-profile";
    } else if (this.$route.path === "/my-shop") {
      this.activeBtn = "my-shop";
    }
  },
};
</script>

<style scoped lang="scss">
.sidebar {
  width: 264px;
  height: calc(100vh - 64px);
  padding: 32px 24px 24px;
  border-right: 1px solid
    var(--Colors-Neutral-Border-colorBorderSecondary, #f0f0f0);
  transition: 0.3s;
  @media (max-width: 639.99px) {
    & {
      width: 93px;
    }
  }
  ul {
    padding: 0;
    li {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        display: flex;
        background: transparent;
        align-items: center;
        gap: 8px;
        padding: 9px 24px;
        border-radius: $main-border-radius;
        width: 100%;
        &.active {
          background: $primary-color;
          color: white;
          svg path {
            fill: #e6f4ff;
          }
        }
      }
      .soon {
        display: inline;
        padding: 1px 8px;
        color: #faad14;
        background-color: #fffbe6;
        border: 1px solid #ffe58f;
        border-radius: 4px;
      }
    }
  }
  @media (max-width: 639.99px) {
    ul {
      li {
        margin-bottom: 16px;
        button {
          padding: 15px;
          span {
            display: none;
          }
        }
        .soon {
          display: none;
        }
      }
    }
  }
}
</style>
