<template>
  <div class="Preview">
    <div v-if="previewProdcut">
      <div class="preview-in-our-shop" v-if="previewProdcut.preview_url">
        <a target="_blank" :href="previewProdcut.preview_url">
          <button>Preview in our shop</button>
        </a>
      </div>
      <div class="content">
        <div class="image-main-content row">
          <div
            class="image col-12 col-md-6"
            v-if="previewProdcut.all_images.length > 1"
          >
            <CustomCarousel :images="previewProdcut.all_images" />
          </div>
          <div class="image col-12 col-md-6" v-else>
            <div class="carousel__item">
              <img :src="previewProdcut.image" alt="Carousel Image" />
            </div>
          </div>
          <div class="main-content col-12 col-md-6">
            <div class="title">
              <h2>{{ productName }}</h2>
            </div>
            <div class="product-description">
              <p>
                {{ productDescription }}
              </p>
            </div>
            <div class="brand-name" v-if="brandName">
              <p>
                Brand Name: <span>{{ brandName }}</span>
              </p>
            </div>
            <div class="barcode" v-if="barcode">
              <p>
                Barcode: <span>{{ barcode }}</span>
              </p>
            </div>
            <div class="sku" v-if="sku">
              <p>
                SKU:
                <span>
                  {{ sku }}
                </span>
              </p>
            </div>
            <div class="bullet-point" v-if="keyBenefits.length">
              <div class="title">
                <h5>Key benefits</h5>
              </div>
              <ul class="point">
                <li
                  v-for="(benefit, index) in keyBenefits"
                  :key="index"
                  class="d-flex align-items-start"
                >
                  <svg
                    class="mr-2 mt-1"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM8 13.8125C4.79063 13.8125 2.1875 11.2094 2.1875 8C2.1875 4.79063 4.79063 2.1875 8 2.1875C11.2094 2.1875 13.8125 4.79063 13.8125 8C13.8125 11.2094 11.2094 13.8125 8 13.8125Z"
                      fill="#1677FF"
                    />
                    <path
                      opacity="0.1"
                      d="M8 2.1875C4.79063 2.1875 2.1875 4.79063 2.1875 8C2.1875 11.2094 4.79063 13.8125 8 13.8125C11.2094 13.8125 13.8125 11.2094 13.8125 8C13.8125 4.79063 11.2094 2.1875 8 2.1875ZM11.0219 5.71406L7.73125 10.2766C7.68526 10.3408 7.62463 10.3931 7.55439 10.4291C7.48414 10.4652 7.40631 10.4841 7.32734 10.4841C7.24838 10.4841 7.17054 10.4652 7.1003 10.4291C7.03006 10.3931 6.96943 10.3408 6.92344 10.2766L4.97656 7.57656C4.91719 7.49375 4.97656 7.37813 5.07812 7.37813H5.81094C5.97187 7.37813 6.12187 7.45625 6.21562 7.58594L7.32812 9.12969L9.78438 5.72344C9.87813 5.59219 10.0297 5.51562 10.1891 5.51562H10.9219C11.0234 5.51562 11.0828 5.63125 11.0219 5.71406Z"
                      fill="#1677FF"
                    />
                    <path
                      d="M10.9211 5.51562H10.1883C10.0289 5.51562 9.87736 5.59219 9.78361 5.72344L7.32735 9.12969L6.21485 7.58594C6.1211 7.45625 5.9711 7.37813 5.81016 7.37813H5.07735C4.97578 7.37813 4.91641 7.49375 4.97578 7.57656L6.92267 10.2766C6.96866 10.3408 7.02929 10.3931 7.09953 10.4291C7.16977 10.4652 7.2476 10.4841 7.32657 10.4841C7.40554 10.4841 7.48337 10.4652 7.55362 10.4291C7.62386 10.3931 7.68449 10.3408 7.73048 10.2766L11.0211 5.71406C11.0821 5.63125 11.0227 5.51562 10.9211 5.51562Z"
                      fill="#1677FF"
                    />
                  </svg>

                  <p>{{ benefit }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="details" v-if="groupedDetailsArray.length">
          <div class="container">
            <div class="title">
              <h2>Achieve Youthful, Radiant Skin Today!</h2>
            </div>
            <table class="my-table">
              <tbody>
                <template
                  v-for="(row, index) in groupedDetailsArray.length"
                  :key="index"
                >
                  <tr class="details-headers">
                    <th v-for="(item, i) in groupedDetailsArray[row]" :key="i">
                      {{ item.name }}
                    </th>
                  </tr>
                  <tr class="details-bodies">
                    <td v-for="(item, i) in groupedDetailsArray[row]" :key="i">
                      <template v-if="typeof item.value === 'string'">
                        {{ item.value }}
                      </template>
                      <template
                        v-else-if="
                          typeof item.value === 'object' &&
                          !Array.isArray(item.value)
                        "
                      >
                        {{ item.value.value }}
                      </template>
                      <template v-else-if="Array.isArray(item.value)">
                        <span
                          v-for="(obj, objIndex) in item.value"
                          :key="objIndex"
                        >
                          {{ obj.value }}
                          <span v-if="objIndex !== item.value.length - 1"
                            >,
                          </span>
                        </span>
                      </template>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="progress position-relative" v-else>
      <div>
        <div class="spinner-border custom-spinner" role="status">
          <span class="sr-only"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Collapse from "@/components/ui/Collapse.vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { mapMutations } from "vuex";
import { getProductDetails } from "@/api/products";
import CustomCarousel from "../ui/CustomCarousel.vue";
export default {
  name: "PreviewComp",
  data() {
    return {
      images: [],
      previewProdcut: null,
      productDetails: [],
      currentSlide: 0,
    };
  },
  props: {
    details: Object,
    idFromStorage: 1,
    productId: null,
  },
  components: {
    CustomCarousel,
  },
  mounted() {
    this.setHideInfoCard(false);
    this.setCurrentStep(2);
    if (this.$route.params.id) {
      this.getProductDataById(this.$route.params.id);
    } else {
      if (this.productId) {
        this.getProductDataById(this.productId);
      }
    }
  },
  unmounted() {
    // this.swiper.destroy();
    this.setHideInfoCard(true);
  },
  watch: {
    productId(newVal) {
      this.previewProdcut = null;
      this.getProductDataById(newVal);
    },
  },
  methods: {
    ...mapMutations(["setHideInfoCard", "setCurrentStep"]),
    slideTo(val) {
      this.currentSlide = val;
    },
    nextSlide() {
      this.$refs.carousel.next();
      this.updateCurrentIndex();
    },
    prevSlide() {
      this.$refs.carousel.prev();
      this.updateCurrentIndex();
    },
    updateCurrentIndex() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    },
    getProductDataById(id) {
      if (id) {
        getProductDetails(id)
          .then((response) => {
            this.previewProdcut = response.result;
            this.productDetails = this.previewProdcut.json_data.details;
            if (this.previewProdcut.images) {
              this.images = JSON.parse(this.previewProdcut.images).map((url) =>
                url.replace(/^"(.*)"$/, "$1").split(",")
              );
              this.keyBenefits =
                this.previewProdcut.json_data.general.bullet_point.value.split(
                  "."
                );
              this.keyBenefits.pop();
            } else {
              this.images = [];
            }
          })
          .catch((error) => {});
      }
    },
  },
  computed: {
    // ...mapGetters(["dataGenerated"]),
    generalJsondata() {
      if (this.previewProdcut.json_data) {
        return this.previewProdcut.json_data.general;
      }
    },
    detailsJsontData() {
      if (this.previewProdcut.json_data) {
        return this.previewProdcut.json_data.details;
      }
    },
    keyBenefits() {
      if (this.generalJsondata) {
        const list =
          this.previewProdcut.json_data.general.bullet_point.value.split(".");
        list.pop();
        return list;
      }
    },
    productName() {
      if (this.generalJsondata) {
        return this.generalJsondata.product_name.value;
      }
    },
    productDescription() {
      if (this.generalJsondata) {
        return this.generalJsondata.product_description.value;
      }
    },
    brandName() {
      if (this.generalJsondata) {
        return this.generalJsondata.brand_name.value;
      }
    },
    barcode() {
      if (this.generalJsondata) {
        return this.generalJsondata.barcode.value;
      }
    },
    sku() {
      if (this.generalJsondata) {
        return this.generalJsondata.sku.value;
      }
    },
    imagesSlider() {
      if (this.previewProdcut.images) {
        const imagesObj = JSON.parse(this.previewProdcut.images);
        const cleanedimagesObj = imagesObj.map((url) =>
          url.replace(/^"(.*)"$/, "$1").split(",")
        );
        return cleanedimagesObj;
      }
    },
    groupedDetailsArray() {
      const result = [];
      const tempArray = [...this.productDetails];

      // Fill the array to the next multiple of 4 with placeholder objects
      while (tempArray.length % 4 !== 0) {
        tempArray.push({ name: "-", value: "-" });
      }

      for (let i = 0; i < tempArray.length; i += 4) {
        result.push(tempArray.slice(i, i + 4));
      }
      return result;
    },
  },
};
</script>
<style scoped lang="scss">
.Preview {
  max-width: 100vw;
  // margin: 32px;
  .preview-in-our-shop {
    width: 100%;
    text-align: right;
    margin-bottom: 16px;
    button {
      background-color: #1677ff;
      color: white;
      border: none;
      padding: 8px 40.5px;
      border-radius: 40px;
      margin-top: 7px;
      margin-bottom: 7px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      transition: all 0.2s;
      &:hover {
        background-color: #4790fd;
      }
    }
  }
  .content {
    width: 100%;
    text-align: left;
    .image-main-content {
      .image {
        min-width: 50%;
        height: 100%;
        overflow: hidden;
        border-radius: 8px;
      }
      .main-content {
        .title {
          margin-top: 16px;
          margin-bottom: 24px;
          h2 {
            color: #000000e0;
            font-size: 30px;
            font-weight: 600;
            line-height: 38px;
          }
        }
        .description {
          margin-bottom: 24px;
          p {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            color: #000000a6;
          }
        }
        .bullet-point {
          .title h5 {
            color: #000000e0;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            margin-bottom: 12px;
          }
          .point {
            margin: 0;
            li {
              font-size: 14px;
              font-weight: 400;
              line-height: 22px;
              color: #000000e0;
              margin-bottom: 12px;
              svg {
                width: 16px;
                height: 16px;
              }
              p {
                width: calc(100% - 24px);
                margin-left: 12px;
              }
            }
          }
        }
      }
    }
    .details {
      background: #f5f5f5;
      border-radius: 8px;
      padding: 32px;
      margin-top: 24px;
      .container {
        width: 100%;
        margin: 24px auto;
        .title {
          h2 {
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            color: #000000e0;
            padding: 8px;
            margin-bottom: 12px;
          }
        }
        .my-table {
          width: 100%;
          tr {
            border-bottom: $main-border-1px;
            td {
              width: 25%;
            }
            &:hover {
              background: #f5f5f5;
            }
          }
          td,
          th {
            padding: 16px;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
          }
          .details-headers {
            background: #0000000f;
            position: relative;
          }
          .details-headers th {
            position: relative;
          }
          .details-headers th:nth-child(-n + 3)::after {
            content: "";
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 1px;
            height: 22px;
            background-color: #0000000f;
          }
          .details-bodies {
            background: #f0f0f0;
          }
        }
      }
    }
  }
}
.product-description,
.brand-name,
.barcode,
.sku {
  color: #000000e0;
  margin-top: 12px;
  span {
    font-weight: 600;
  }
}

.progress {
  height: 72vh;
}
.carousel__item {
  height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: auto;
    height: 100%;
  }
  // background-color: var(--vc-clr-primary);
  // color: var(--vc-clr-white);
  // font-size: 20px;
  // border-radius: 8px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}

// .carousel__slide {
//   padding: 10px;
// }

// .carousel__prev,
// .carousel__next {
//   box-sizing: content-box;
//   border: 5px solid white;
// }
// .carousel__pagination-button:after {
//   background: red;
// }
// .carousel {
//   margin-top: 0;
//   .carousel__pagination-button:after {
//     border-radius: 5px;
//     width: 10px;
//     height: 10px;
//   }
// }
.custom-spinner {
  color: #1677ff;
  border-width: 6px;
  width: 80px;
  height: 80px;
}
// .carousel {
//   margin: 0;
//   img {
//     height: 408px;
//   }
// }
</style>
