<template>
  <button>
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "BlueBase8Btn",
};
</script>

<style scoped lang="scss">
button {
  display:flex;
  align-items: center;
  height: 32px;
  border: 1px solid var(--Colors-Base-Blue-8, #003eb3);
  border-radius: 8px;
  background: #fff;
  padding-left: 8px;
  padding-right: 8px;
  color: var(--Colors-Base-Blue-8, #003eb3);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  gap: 8px;
  &:hover {
    border: 1px solid #2c80ff;
    color: #2c80ff;
  }
}
</style>
