<template>
  <!--=============== HEADER ===============-->
  <header class="header" :class="{ fixed: isHeaderFixed }">
    <nav class="nav container">
      <div class="nav__data">
        <a href="#" class="nav__logo">
          <img src="../../assets/images/logo.svg" width="150px" alt="" />
        </a>

        <div
          class="nav__toggle"
          id="nav-toggle "
          :id="toggleId"
          @click="toggleMenu"
          :class="{ 'show-icon': isIconVisible }"
        >
          <i class="ri-menu-line nav__toggle-menu"></i>
          <i class="ri-close-line nav__toggle-close"></i>
        </div>
      </div>

      <!--=============== NAV MENU ===============-->
      <div
        class="nav__menu"
        id="nav-menu navId ml-4"
        :class="{ 'show-menu': isMenuVisible }"
      >
        <ul class="nav__list">
          <li>
            <a href="#" class="nav__link">Home</a>
          </li>

          <!--=============== DROPDOWN 1 ===============-->
          <!-- <li class="dropdown__item">
            <div class="nav__link dropdown__button">
              Products <i class="ri-arrow-down-s-line dropdown__arrow"></i>
            </div>

            <div class="dropdown__container">
              <div class="dropdown__content">
                <div class="dropdown__group">
                  <div class="dropdown__icon">
                    <i class="ri-flashlight-line"></i>
                  </div>

                  <span class="dropdown__title">Lorem, ipsum dolor.</span>

                  <ul class="dropdown__list">
                    <li>
                      <a href="#" class="dropdown__link">About</a>
                    </li>
                    <li>
                      <a href="#" class="dropdown__link">Services</a>
                    </li>
                  </ul>
                </div>

                <div class="dropdown__group">
                  <div class="dropdown__icon">
                    <i class="ri-heart-3-line"></i>
                  </div>

                  <span class="dropdown__title">Lorem, ipsum.</span>

                  <ul class="dropdown__list">
                    <li>
                      <a href="#" class="dropdown__link"
                        >Solutions<span class="note ms-2">New</span></a
                      >
                    </li>
                    <li>
                      <a href="#" class="dropdown__link">Blog</a>
                    </li>
                  </ul>
                </div>

                <div class="dropdown__group">
                  <div class="dropdown__icon">
                    <i class="ri-file-paper-2-line"></i>
                  </div>

                  <span class="dropdown__title">lorem</span>

                  <ul class="dropdown__list">
                    <li>
                      <a href="#" class="dropdown__link">Pricing</a>
                    </li>
                    <li>
                      <a href="#" class="dropdown__link">Contact</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li> -->

          <li
            v-for="(item, index) in dropdownItems"
            :key="index"
            class="dropdown__item"
            :class="{ 'show-dropdown': item.showDropdown }"
          >
            <div
              class="nav__link dropdown__button"
              @click="toggleDropdown(index)"
            >
              {{ item.title }}
              <i :class="item.iconClass + ' dropdown__arrow'"></i>
            </div>
            <div
              class="dropdown__container"
              :style="{ height: item.showDropdown ? item.height : '0px' }"
            >
              <div class="dropdown__content">
                <div
                  v-for="(group, groupIndex) in item.groups"
                  :key="groupIndex"
                  class="dropdown__group"
                >
                  <div class="dropdown__icon">
                    <i :class="group.iconClass"></i>
                  </div>
                  <span class="dropdown__title">{{ group.title }}</span>
                  <ul class="dropdown__list">
                    <li
                      v-for="(link, linkIndex) in group.links"
                      :key="linkIndex"
                    >
                      <a :href="link.href" class="dropdown__link"
                        >{{ link.text
                        }}<span v-if="link.note" class="note ms-2">{{
                          link.note
                        }}</span></a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>

          <!--=============== DROPDOWN 2 ===============-->
          <!-- <li class="dropdown__item">
            <div class="nav__link dropdown__button">
              Resources <i class="ri-arrow-down-s-line dropdown__arrow"></i>
            </div>

            <div class="dropdown__container">
              <div class="dropdown__content">
                <div class="dropdown__group">
                  <div class="dropdown__icon">
                    <i class="ri-code-line"></i>
                  </div>

                  <span class="dropdown__title">lorem</span>

                  <ul class="dropdown__list">
                    <li>
                      <a href="#" class="dropdown__link"
                        >Marketing & Branding</a
                      >
                    </li>
                    <li>
                      <a href="#" class="dropdown__link">Strategic Planning</a>
                    </li>
                  </ul>
                </div>

                <div class="dropdown__group">
                  <div class="dropdown__icon">
                    <i class="ri-pen-nib-line"></i>
                  </div>

                  <span class="dropdown__title">lorem</span>

                  <ul class="dropdown__list">
                    <li>
                      <a href="#" class="dropdown__link">Business Analysis</a>
                    </li>
                    <li>
                      <a href="#" class="dropdown__link">Genrate</a>
                    </li>
                    <li>
                      <a href="#" class="dropdown__link">Genrate</a>
                    </li>
                  </ul>
                </div>

                <div class="dropdown__group">
                  <div class="dropdown__icon">
                    <i class="ri-apps-2-line"></i>
                  </div>

                  <span class="dropdown__title">lorem</span>

                  <ul class="dropdown__list">
                    <li>
                      <a href="#" class="dropdown__link">API</a>
                    </li>
                    <li>
                      <a href="#" class="dropdown__link">API</a>
                    </li>
                    <li>
                      <a href="#" class="dropdown__link">See all</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li> -->

          <li>
            <a href="#" class="nav__link">Pricing</a>
          </li>
          <div class="nav__login" v-if="!$store.getters.user">
            <a href="login" class="log">Log in</a>
            <a href="" class="blue-btn">Get Started Free</a>
          </div>
          <div class="nav__login" v-else>
            <a href="/wizard/select-category" class="blue-btn">List Your Product</a>
            <a href="history" class="log">Listing History</a>
          </div>
        </ul>
      </div>
    </nav>
  </header>

  <!-- start main  -->
  <main>
    <div class="container">
      <div class="main-header">
        <div class="link-green d-flex gap-3 wow fadeIn">
          <div class="dot-box d-flex align- align-items-center gap-2">
            <span class="glowy-dot"></span>
            <span>New feature</span>
          </div>
          <a href="#" class="d-flex align- align-items-center gap-2">
            <span>Check out the team dashboard</span>
            <i class="ri-arrow-right-line"></i>
          </a>
        </div>
        <h1 class="my-5 wow fadeIn" data-wow-delay=".2s">
          Ready to Dominate Amazon?
        </h1>
        <p class="my-5 wow fadeIn" data-wow-delay=".25s">
          Does crafting the perfect product listing feel like an uphill battle?
          We get it – compelling product descriptions aren’t everyone’s cup of
          tea. But guess what?
        </p>
        <div
          class="main-links d-flex gap-3 mx-auto align- align-items-center justify-content-center mb-3 wow fadeIn"
          data-wow-delay=".3s"
        >
          <a href="#" class="video"
            ><i class="ri-play-circle-line me-2 fs-5"></i>Demo</a
          >
          <a href="#" class="blue-btn">Get Started Free</a>
        </div>
        <div>
          <img
            src="../../assets/images/Content.webp"
            width="100%"
            class="mb-5 wow fadeIn"
            data-wow-delay=".35s"
            alt=""
          />
        </div>
      </div>
    </div>
  </main>
  <!-- end main  -->

  <!-- start Trusted section  -->
  <section id="trusted">
    <div class="container">
      <div class="trusted-imgs">
        <p class="mb-4 wow fadeInDown">Trusted by 4,0+ companies</p>
        <div class="d-flex justify-content-center gap-4 align-items-center">
          <img
            src="../../assets/images/Fictional company logo.png"
            width="160px"
            class="wow fadeInUp"
            data-wow-delay=".15s"
            alt=""
          />
          <img
            src="../../assets/images/Fictional company logo-1.png"
            width="160px"
            class="wow fadeInUp"
            data-wow-delay=".2s"
            alt=""
          />
          <img
            src="../../assets/images/Fictional company logo-2.png"
            width="160px"
            class="wow fadeInUp"
            data-wow-delay=".25s"
            alt=""
          />
          <img
            src="../../assets/images/Fictional company logo-3.png"
            width="160px"
            class="wow fadeInUp"
            data-wow-delay=".3s"
            alt=""
          />
          <img
            src="../../assets/images/Fictional company logo-4.png"
            width="160px"
            class="wow fadeInUp"
            data-wow-delay=".35s"
            alt=""
          />
        </div>
      </div>
    </div>
  </section>
  <!-- end Trusted section  -->
  <!-- start AI listings section  -->
  <section id="ai">
    <div class="container">
      <div class="row justify-content-between align- align-items-center gap-3">
        <div class="col-lg-4 wow fadeInRight">
          <h3>Get 5 AI listings for Free!</h3>
          <p class="header-p">
            Does crafting the perfect product listing feel like an uphill
            battle? We get it – compelling product descriptions aren’t
            everyone’s cup of tea. But guess what? They're ours! Introducing:
            Amazon Listing Powered by AI – your secret weapon for standout
            listings that sell.
          </p>
        </div>
        <div class="col-lg-6">
          <div class="row m-0">
            <div
              class="col-md-6 text-center p-2 p-lg-3 wow fadeInUp"
              data-wow-delay=".15s"
            >
              <div class="ai-icon">
                <i class="ri-camera-line"></i>
              </div>
              <h4 class="my-3">Capture</h4>
              <p>
                Whether you have a team of 2 or 200, our shared team inboxes
                keep everyone on the same page and in the loop.
              </p>
            </div>

            <div
              class="col-md-6 text-center p-2 p-lg-3 wow fadeInUp"
              data-wow-delay=".2s"
            >
              <div class="ai-icon">
                <i class="ri-cursor-line"></i>
              </div>
              <h4 class="my-3">Submit</h4>
              <p>
                An all-in-one customer service platform that helps you balance
                everything your customers need to be happy.
              </p>
            </div>

            <div
              class="col-md-6 text-center p-2 p-lg-3 wow fadeInUp"
              data-wow-delay=".25s"
            >
              <div class="ai-icon">
                <i class="ri-bard-line"></i>
              </div>
              <h4 class="my-3">AI Generate</h4>
              <p>
                Measure what matters with Untitled’s easy-to-use reports. You
                can filter, export, and drilldown on the data in a couple
                clicks.
              </p>
            </div>

            <div
              class="col-md-6 text-center p-2 p-lg-3 wow fadeInUp"
              data-wow-delay=".3s"
            >
              <div class="ai-icon">
                <i class="ri-shopping-basket-2-line"></i>
              </div>
              <h4 class="my-3">Sell</h4>
              <p>
                We’re an extension of your customer service team, and all of our
                resources are free. Chat to our friendly team 24/7 when you need
                help.
              </p>
            </div>
          </div>
        </div>
      </div>
      <a href="#" class="blue-btn wow fadeIn" data-wow-delay=".35s"
        >Get Started Free<i class="ri-arrow-right-line"></i
      ></a>
    </div>
  </section>
  <!-- end AI listings section  -->
  <!-- start RONOMO section  -->
  <section id="ronomo">
    <div class="container">
      <div class="row m-0 justify-content-between gap-4 align-items-center">
        <div class="col-lg-7 wow fadeInRight">
          <div class="link-green d-flex gap-3">
            <div class="dot-box d-flex align- align-items-center gap-2">
              <span class="glowy-dot"></span>
              <span>Ai Generate</span>
            </div>
            <a href="#" class="d-flex align- align-items-center gap-2">
              <span>www.Listapro.com</span>
              <i class="ri-arrow-right-line"></i>
            </a>
          </div>

          <h3 class="my-3">RONOMO Men's Fashion Printed</h3>
          <p>
            Stretch Fabric: The long sleeve shirt is made of stretch fabric,
            which makes you move body easier, soft and comfortable with
            anti-shrink, good color fastness.
          </p>
          <p>
            Wrinkle Free: High-Density fabric and Machine Knitting Process makes
            the solid dress shirt not easy to wrinkle, easy to care.
          </p>
          <div
            class="ronomo d-flex justify-content-between align- align-items-center gap-2 mb-3"
          >
            <span>Color</span>
            <img
              src="../../assets/images/Avatar group.png"
              width="150px"
              alt=""
            />
            <div>
              <div
                class="d-flex justify-content-start align- align-items-center gap-2"
              >
                <img src="../../assets/images/Stars.svg" width="115px" alt="" />
                <span>5.0</span>
              </div>
              <p>Top Casual Print T-Shirt</p>
            </div>
          </div>

          <a href="#" class="blue-btn"
            >Get Started Free<i class="ri-arrow-right-line"></i
          ></a>
        </div>
        <div class="col-lg-4 text-center wow fadeInLeft">
          <div class="position-position-relative ronomo-img">
            <img src="../../assets/images/Vector.png" class="pos-img" alt="" />
            <img
              src="../../assets/images/ronomo.svg"
              width="100%"
              class="text-center"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- end RONOMO section  -->
  <!-- start table img section  -->
  <section id="table-img">
    <div class="container">
      <div
        class="blue-div de-d-flex justify-content-center align- align-items-center gap-2 wow fadeIn"
      >
        <i class="ri-checkbox-circle-fill"></i>
        <span>Upload your product images</span>
      </div>

      <div
        class="steps d-flex justify-content-between align- align-items-center my-3 wow fadeInLeft"
        data-wow-delay=".15s"
      >
        <div class="step-div">
          <span class="num">01</span>
          <span class="text"
            >Generate or rewrite product descriptions in bulk</span
          >
        </div>
        <i
          class="ri-arrow-right-s-fill wow fadeInLeft"
          data-wow-delay=".2s"
        ></i>
        <div class="step-div wow fadeInLeft" data-wow-delay=".25s">
          <span class="num">02</span>
          <span class="text"
            >Generate or rewrite product descriptions in bulk</span
          >
        </div>
        <i
          class="ri-arrow-right-s-fill wow fadeInLeft"
          data-wow-delay=".3s"
        ></i>
        <div class="step-div wow fadeInLeft" data-wow-delay=".35s">
          <span class="num">03</span>
          <span class="text">Export products with ease</span>
        </div>
      </div>

      <img
        src="../../assets/images/Table.svg"
        alt=""
        width="100%"
        class="table-img my-3 wow fadeIn"
        data-wow-delay=".35s"
      />

      <a href="#" class="blue-btn"
        >Get Started Free<i
          class="ri-arrow-right-line wow fadeIn"
          data-wow-delay=".35s"
        ></i
      ></a>
    </div>
  </section>
  <!-- end table img section  -->
  <!-- start Empower section  -->
  <section id="empower">
    <div class="container">
      <div class="title mb-sm-5 mb-3 wow fadeInDown" v-wow>
        <span>Features</span>
        <h3 class="my-3">Empower Your Listings, Elevate Your Sales!</h3>
        <p>
          We’re not just another AI platform, we’re the secure, operating system
          for business-critical operations.
        </p>
      </div>

      <div class="d-flex flex-column justify-content-center d-flex-md-row">
        <div
          class="col-lg-4 col-md-6 px-sm-3 py-sm-3 px-1 py-2 wow fadeInUp"
          data-wow-delay=".2s"
        >
          <div class="empower-cards">
            <div class="icon-contain position-relative">
              <span class="card-icon">
                <i class="ri-apps-line"></i>
              </span>
              <span class="bg"></span>
            </div>
            <h4 class="my-2">Instant Listings</h4>
            <p>
              From hours to minutes! Our AI crafts listings rapidly, giving you
              more freedom.
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-6 px-sm-3 py-sm-3 px-1 py-2 wow fadeInUp"
          data-wow-delay=".3s"
        >
          <div class="empower-cards">
            <div class="icon-contain position-relative">
              <span class="card-icon">
                <i class="ri-bar-chart-box-line"></i>
              </span>
              <span class="bg"></span>
            </div>
            <h4 class="my-2">Skyrocket Rankings</h4>
            <p>
              With AI-optimized content generation, you're poised to top the
              search results
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-6 px-sm-3 py-sm-3 px-1 py-2 wow fadeInUp"
          data-wow-delay=".4s"
        >
          <div class="empower-cards">
            <div class="icon-contain position-relative">
              <span class="card-icon">
                <i class="ri-line-chart-line"></i>
              </span>
              <span class="bg"></span>
            </div>
            <h4 class="my-2">Maximize Conversions</h4>
            <p>
              Win over shoppers with captivating, information-rich listings and
              cataloge.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- end Empower section  -->
  <!-- start Works section  -->
  <section id="works">
    <div class="container">
      <div class="title text-center wow fadeInDown">
        <span>Features</span>
        <h3 class="my-3">How It Works?</h3>
        <p class="w-100">
          Choose your path to a game-changing listing there is two options for
          generation listing
        </p>
      </div>
    </div>
    <div class="row m-0 p-0 align- align-items-center py-5 my-5">
      <div class="text col-md-5 mx-auto wow fadeInRight">
        <div class="icon">
          <i class="ri-chat-3-line"></i>
        </div>
        <h4 class="my-3">Generate Listing from Image.</h4>
        <p class="out">
          Whether you have a team of 2 or 200, our shared team inboxes keep
          everyone on the same page and in the loop.
        </p>
        <ul class="d-flex flex-column  gap-4 mt-3 mt-lg-5">
          <li class="d-flex align- align-items-center gap-3">
            <i class="ri-checkbox-circle-line"></i>
            <span>Upload your product image.</span>
          </li>
          <li class="d-flex align- align-items-center gap-3">
            <i class="ri-checkbox-circle-line"></i>
            <span>Our AI analyzes and crafts a compelling listing.</span>
          </li>
          <li class="d-flex align- align-items-center gap-3">
            <i class="ri-checkbox-circle-line"></i>
            <span>Review, tweak, and be amazed!</span>
          </li>
          <li class="d-flex align- align-items-center gap-3">
            <i class="ri-checkbox-circle-line"></i>
            <span>Launch it on Amazon with a click on "Publish Listing".</span>
          </li>
        </ul>
      </div>
      <div class="col-md-6 text-start p-0 wow fadeInLeft">
        <img src="../../assets/images/work1.svg" width="100%" alt="" />
      </div>
    </div>

    <div class="row m-0 p-0 align-items-center">
      <div class="col-md-6 text-start p-0 wow fadeInRight">
        <img src="../../assets/images/works2.svg" width="100%" alt="" />
      </div>
      <div class="text col-md-5 mx-auto wow fadeInLeft">
        <div class="icon">
          <i class="ri-flashlight-line"></i>
        </div>
        <h4 class="my-3">Generate Listing from Text</h4>
        <p class="out">
          An all-in-one customer service platform that helps you balance
          everything your customers need to be happy.
        </p>
        <ul class="d-flex flex-column  gap-4 mt-3 mt-lg-5">
          <li class="d-flex align- align-items-center gap-3">
            <i class="ri-checkbox-circle-line"></i>
            <span
              >Enter your product specifics: name, features, and a brief
              description.</span
            >
          </li>
          <li class="d-flex align- align-items-center gap-3">
            <i class="ri-checkbox-circle-line"></i>
            <span>Hit “Generate Listing”.</span>
          </li>
          <li class="d-flex align- align-items-center gap-3">
            <i class="ri-checkbox-circle-line"></i>
            <span>Refine and see your product shine!</span>
          </li>
          <li class="d-flex align- align-items-center gap-3">
            <i class="ri-checkbox-circle-line"></i>
            <span>Propel it to Amazon with "Publish Listing".</span>
          </li>
        </ul>
      </div>
    </div>
  </section>
  <!-- end Works section  -->
  <!-- start pricing section  -->
  <section id="pricing">
    <div class="container">
      <!-- title  -->
      <div class="title text-center wow fadeInDown">
        <span>Pricing</span>
        <h3 class="my-3">Explore our pricing plans</h3>
        <p class="w-100">
          Choose the best plan for your business. Change plans as you grow.
        </p>
      </div>
      <!-- links  -->
      <div
        class="main-links d-flex gap-3 mx-auto align- align-items-center justify-content-center my-5 wow fadeIn"
      >
        <a href="#" class="video">Chat to sales</a>
        <a href="#" class="blue-btn">Get Started Free</a>
      </div>
      <!-- cards  -->
      <div class="row justify-content-center m-0">
        <!-- start card  -->
        <div
          class="col-lg-4 col-md-6 px-md-3 py-5 px-1 wow fadeInUp"
          data-wow-delay=".2s"
        >
          <div class="price-card">
            <!-- icon  -->
            <div class="position-absolute">
              <div class="icon-contain position-relative">
                <span class="card-icon">
                  <i class="ri-stack-line"></i>
                </span>
                <span class="bg"></span>
              </div>
            </div>
            <!-- head  -->
            <h5 class="text-center">Basic plan</h5>
            <h4 class="text-center">$00/mth</h4>
            <p class="text-center">Our most popular plan.</p>

            <ul class="p-0">
              <li>
                <div class="i">
                  <i class="ri-check-fill"></i>
                </div>
                <span>5 Listings</span>
              </li>

              <li>
                <div class="i">
                  <i class="ri-check-fill"></i>
                </div>
                <span>SEO Optimization</span>
              </li>

              <li>
                <div class="i">
                  <i class="ri-check-fill"></i>
                </div>
                <span>Title Content</span>
              </li>

              <li>
                <div class="i">
                  <i class="ri-check-fill"></i>
                </div>
                <span>Bullet Points Content</span>
              </li>

              <li>
                <div class="i">
                  <i class="ri-check-fill"></i>
                </div>
                <span>Description Content</span>
              </li>

              <li class="light">
                <div class="i">
                  <i class="ri-check-fill"></i>
                </div>
                <span>Export Listing</span>
              </li>

              <li class="light">
                <div class="i">
                  <i class="ri-check-fill"></i>
                </div>
                <span>Unlimited Revisions</span>
              </li>

              <li class="light">
                <div class="i">
                  <i class="ri-check-fill"></i>
                </div>
                <span>English Only</span>
              </li>
            </ul>

            <a href="" class="blue-btn">Get Started Free</a>
          </div>
        </div>

        <!-- start card  -->
        <div
          class="col-lg-4 col-md-6 px-md-3 py-5 px-1 wow fadeInUp"
          data-wow-delay=".25s"
        >
          <div class="price-card">
            <!-- icon  -->
            <div class="position-absolute">
              <div class="icon-contain position-relative">
                <span class="card-icon">
                  <i class="ri-archive-stack-line"></i>
                </span>
                <span class="bg"></span>
              </div>
            </div>
            <!-- head  -->
            <h5 class="text-center">Business plan</h5>
            <h4 class="text-center">$20/mth</h4>
            <p class="text-center">Advanced features and reporting.</p>

            <ul class="p-0">
              <li>
                <div class="i">
                  <i class="ri-check-fill"></i>
                </div>
                <span>5 Listings</span>
              </li>

              <li>
                <div class="i">
                  <i class="ri-check-fill"></i>
                </div>
                <span>SEO Optimization</span>
              </li>

              <li>
                <div class="i">
                  <i class="ri-check-fill"></i>
                </div>
                <span>Title Content</span>
              </li>

              <li>
                <div class="i">
                  <i class="ri-check-fill"></i>
                </div>
                <span>Bullet Points Content</span>
              </li>

              <li>
                <div class="i">
                  <i class="ri-check-fill"></i>
                </div>
                <span>Description Content</span>
              </li>

              <li>
                <div class="i">
                  <i class="ri-check-fill"></i>
                </div>
                <span>Export Listing</span>
              </li>

              <li>
                <div class="i">
                  <i class="ri-check-fill"></i>
                </div>
                <span>Unlimited Revisions</span>
              </li>

              <li class="light">
                <div class="i">
                  <i class="ri-check-fill"></i>
                </div>
                <span>English Only</span>
              </li>
            </ul>

            <a href="" class="blue-btn">Get Started Free</a>
          </div>
        </div>

        <!-- start card  -->
        <div
          class="col-lg-4 col-md-6 px-md-3 py-5 px-1 wow fadeInUp"
          data-wow-delay=".15s"
        >
          <div class="price-card">
            <!-- icon  -->
            <div class="position-absolute">
              <div class="icon-contain position-relative">
                <span class="card-icon">
                  <i class="ri-bard-line"></i>
                </span>
                <span class="bg"></span>
              </div>
            </div>
            <!-- head  -->
            <h5 class="text-center">Enterprise plan</h5>
            <h4 class="text-center">$40/mth</h4>
            <p class="text-center">Unlimited features.</p>

            <ul class="p-0">
              <li>
                <div class="i">
                  <i class="ri-check-fill"></i>
                </div>
                <span>5 Listings</span>
              </li>

              <li>
                <div class="i">
                  <i class="ri-check-fill"></i>
                </div>
                <span>SEO Optimization</span>
              </li>

              <li>
                <div class="i">
                  <i class="ri-check-fill"></i>
                </div>
                <span>Title Content</span>
              </li>

              <li>
                <div class="i">
                  <i class="ri-check-fill"></i>
                </div>
                <span>Bullet Points Content</span>
              </li>

              <li>
                <div class="i">
                  <i class="ri-check-fill"></i>
                </div>
                <span>Description Content</span>
              </li>

              <li>
                <div class="i">
                  <i class="ri-check-fill"></i>
                </div>
                <span>Export Listing</span>
              </li>

              <li>
                <div class="i">
                  <i class="ri-check-fill"></i>
                </div>
                <span>Unlimited Revisions</span>
              </li>

              <li>
                <div class="i">
                  <i class="ri-check-fill"></i>
                </div>
                <span>English Only</span>
              </li>
            </ul>

            <a href="" class="blue-btn">Get Started Free</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- end pricing section  -->
  <!-- start swiper  -->
  <section id="swiper">
    <div class="container">
      <div class="swiper mySwiper wow fadeIn">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="swiper-card">
              <p>
                “They meticulously analyzed our operations, provided strategic
                guidance, and implemented solutions that were game-changers for
                us. Highly recommended for anyone seeking top-notch consulting
                services!"
              </p>
              <div class="swiper-img">
                <img src="../../assets/images/char.svg" alt="" />
              </div>
              <h5 class="my-3">Johan Sabetzi</h5>
              <span>Business Development at Innovation Co.</span>
              <div class="stars my-sm-3 my-1">
                <i class="ri-star-fill"></i>
                <i class="ri-star-fill"></i>
                <i class="ri-star-fill"></i>
                <i class="ri-star-fill"></i>
                <i class="ri-star-fill"></i>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="swiper-card">
              <p>
                “They meticulously analyzed our operations, provided strategic
                guidance, and implemented solutions that were game-changers for
                us. Highly recommended for anyone seeking top-notch consulting
                services!"
              </p>
              <div class="swiper-img">
                <img src="../../assets/images/char.svg" alt="" />
              </div>
              <h5 class="my-3">Johan Sabetzi</h5>
              <span>Business Development at Innovation Co.</span>
              <div class="stars my-sm-3 my-1">
                <i class="ri-star-fill"></i>
                <i class="ri-star-fill"></i>
                <i class="ri-star-fill"></i>
                <i class="ri-star-fill"></i>
                <i class="ri-star-fill"></i>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="swiper-card">
              <p>
                “They meticulously analyzed our operations, provided strategic
                guidance, and implemented solutions that were game-changers for
                us. Highly recommended for anyone seeking top-notch consulting
                services!"
              </p>
              <div class="swiper-img">
                <img src="../../assets/images/char.svg" alt="" />
              </div>
              <h5 class="my-3">Johan Sabetzi</h5>
              <span>Business Development at Innovation Co.</span>
              <div class="stars my-sm-3 my-1">
                <i class="ri-star-fill"></i>
                <i class="ri-star-fill"></i>
                <i class="ri-star-fill"></i>
                <i class="ri-star-fill"></i>
                <i class="ri-star-fill"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </section>
  <!-- end swiper  -->
  <!-- start blog section  -->
  <section id="blog">
    <div class="container">
      <div class="d-flex justify-content-between align-items-start">
        <div class="title mb-sm-5 mb-3 wow fadeInDown">
          <span>Our blog</span>
          <h3 class="my-3">Lastest blog posts</h3>
          <p class="w-100">
            Tool and strategies modern teams need to help their companies grow.
          </p>
        </div>
        <a href="#" class="blue-btn">View all posts</a>
      </div>

      <div class="row m-0 justify-content-center">
        <!-- start card  -->
        <div
          class="col-lg-4 col-md-6 px-sm-3 px-1 py-3 wow fadeInUp"
          data-wow-delay=".2s"
        >
          <div class="blog-card">
            <div class="blog-img">
              <img src="../../assets/images/blog1.svg" alt="" />
            </div>
            <span class="blue my-2 my-sm-3">AI Genrate</span>
            <div
              class="d-flex overfl justify-content-between align-items-start"
            >
              <h5>UX review presentations</h5>
              <a href="#"><i class="ri-arrow-right-up-line"></i></a>
            </div>
            <p>
              How do you create compelling presentations that wow your
              colleagues and impress your managers?
            </p>
            <h6>Ahmed Alsaidi</h6>
            <span class="gray">20 Jan 2024</span>
          </div>
        </div>

        <!-- start card  -->
        <div
          class="col-lg-4 col-md-6 px-sm-3 px-1 py-3 wow fadeInUp"
          data-wow-delay=".3s"
        >
          <div class="blog-card">
            <div class="blog-img">
              <img src="../../assets/images/blog-2.svg" alt="" />
            </div>
            <span class="blue my-2 my-sm-3">API</span>
            <div
              class="d-flex overfl justify-content-between align-items-start"
            >
              <h5>Migrating to Linear 101</h5>
              <a href="#"><i class="ri-arrow-right-up-line"></i></a>
            </div>
            <p>
              Linear helps streamline software projects, sprints, tasks, and bug
              tracking. Here’s how to get started.
            </p>
            <h6>Ahmed Alsaidi</h6>
            <span class="gray">19 Jan 2024</span>
          </div>
        </div>

        <!-- start card  -->

        <div
          class="col-lg-4 col-md-6 px-sm-3 px-1 py-3 wow fadeInUp"
          data-wow-delay=".4s"
        >
          <div class="blog-card">
            <div class="blog-img">
              <img src="../../assets/images/blog3.svg" alt="" />
            </div>
            <span class="blue my-2 my-sm-3">Software Engineering</span>
            <div
              class="d-flex overfl justify-content-between align-items-start"
            >
              <h5>Building your API stack</h5>
              <a href="#"><i class="ri-arrow-right-up-line"></i></a>
            </div>
            <p>
              The rise of RESTful APIs has been met by a rise in tools for
              creating, testing, and managing them.
            </p>
            <h6>Ahmed Alsaidi</h6>
            <span class="gray">18 Jan 2024</span>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- end blog section  -->
  <!-- start footer  -->
  <footer class="wow fadeIn" data-wow-delay=".2s">
    <div class="container">
      <div class="row m-0 justify-content-between">
        <div class="col-xl-2 col-lg-4 col-sm-6 p-2">
          <h6>Product</h6>
          <ul>
            <li><a href="#">About</a></li>
            <li><a href="#">Services</a></li>
            <li>
              <a href="#">
                Solutions
                <span class="note">New</span>
              </a>
            </li>
            <li><a href="#">Blog</a></li>
            <li><a href="#">Pricing</a></li>
            <li><a href="#">Contact</a></li>
          </ul>
        </div>
        <div class="col-xl-2 col-lg-4 col-sm-6 p-2">
          <h6>Resources</h6>
          <ul>
            <li><a href="#">Marketing & Branding</a></li>
            <li><a href="#">Strategic Planning</a></li>
            <li><a href="#">Business Analysis</a></li>
            <li><a href="#">Genrate</a></li>
            <li><a href="#">API</a></li>
            <li><a href="#">See all</a></li>
          </ul>
        </div>
        <div class="col-xl-2 col-lg-4 col-sm-6 p-2">
          <h6>Solutions</h6>
          <ul>
            <li><a href="#">Startups</a></li>
            <li><a href="#">Enterprise</a></li>
            <li><a href="#">Government</a></li>
            <li><a href="#">SaaS centre</a></li>
            <li><a href="#">Marketplaces</a></li>
            <li><a href="#">Ecommerce</a></li>
          </ul>
        </div>
        <div class="col-xl-2 col-lg-4 col-sm-6 p-2">
          <h6>Legal</h6>
          <ul>
            <li><a href="#">Terms</a></li>
            <li><a href="#">Privacy</a></li>
            <li><a href="#">Cookies</a></li>
            <li><a href="#">Licenses</a></li>
            <li><a href="#">Settings</a></li>
            <li><a href="#">Contact</a></li>
          </ul>
        </div>
        <div class="col-xl-2 col-lg-4 col-sm-6 p-2">
          <h6>Social</h6>
          <ul>
            <li><a href="#">Twitter</a></li>
            <li><a href="#">LinkedIn</a></li>
            <li><a href="#">Facebook</a></li>
            <li><a href="#">GitHub</a></li>
          </ul>
        </div>
      </div>
      <div
        class="rights d-flex justify-content-between align- align-items-center py-3 py-md-4"
      >
        <img src="../../assets/images/logo.svg" alt="" />
        <span>© 2024 Listapro. All rights reserved.</span>
      </div>
    </div>
  </footer>
  <!-- end footer  -->
  <!-- scroll to top  -->
  <a
    href="#"
    class="to-top"
    :class="{ active: isToTopActive }"
    @click="scrollToTop"
  >
    <i class="fas fa-chevron-up"></i>
  </a>
</template>

<script>
import Swiper from "swiper/bundle";
import "swiper/swiper-bundle.css";
import { mapGetters } from "vuex";

export default {
  name: "landingPage",

  data() {
    return {
      navId: "nav-menu",
      toggleId: "nav-toggle",
      isMenuVisible: false,
      isIconVisible: false,
      isHeaderFixed: false,
      isToTopActive: false,
      dropdownItems: [
        {
          title: "Products",
          iconClass: "ri-arrow-down-s-line",
          showDropdown: false,
          height: "0px",
          groups: [
            {
              iconClass: "ri-flashlight-line",
              title: "Lorem, ipsum dolor.",
              links: [
                {
                  href: "#",
                  text: "About",
                },
                {
                  href: "#",
                  text: "Services",
                },
              ],
            },
            {
              iconClass: "ri-heart-3-line",
              title: "Lorem, ipsum.",
              links: [
                {
                  href: "#",
                  text: "Solutions",
                  note: "New",
                },
                {
                  href: "#",
                  text: "Blog",
                },
              ],
            },
            {
              iconClass: "ri-file-paper-2-line",
              title: "lorem",
              links: [
                {
                  href: "#",
                  text: "Pricing",
                },
                {
                  href: "#",
                  text: "Contact",
                },
              ],
            },
          ],
        },
        {
          title: "Resources",
          iconClass: "ri-arrow-down-s-line",
          showDropdown: false,
          height: "0px",
          groups: [
            {
              iconClass: "ri-code-line",
              title: "lorem",
              links: [
                {
                  href: "#",
                  text: "Marketing & Branding",
                },
                {
                  href: "#",
                  text: "Strategic Planning",
                },
              ],
            },
            {
              iconClass: "ri-pen-nib-line",
              title: "Lorem, ipsum.",
              links: [
                {
                  href: "#",
                  text: "Business Analysis",
                  note: "New",
                },
                {
                  href: "#",
                  text: "Genrate",
                },
                {
                  href: "#",
                  text: "Genrate",
                },
              ],
            },
            {
              iconClass: "ri-apps-2-line",
              title: "lorem",
              links: [
                {
                  href: "#",
                  text: "API",
                },
                {
                  href: "#",
                  text: "API",
                },
                {
                  href: "#",
                  text: "See all",
                },
              ],
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["user", "isAuthenticated"]),
  },
  methods: {
    toggleMenu() {
      this.isMenuVisible = !this.isMenuVisible;
      this.isIconVisible = !this.isIconVisible;
    },
    toggleDropdown(index) {
      const currentDropdown = this.dropdownItems[index];
      currentDropdown.showDropdown = !currentDropdown.showDropdown;
      currentDropdown.height = currentDropdown.showDropdown
        ? `fit-content`
        : "0px";
      this.dropdownItems.forEach((item, idx) => {
        if (idx !== index) {
          item.showDropdown = false;
          item.height = "0px";
        }
      });
    },
    handleScroll() {
      this.isHeaderFixed = window.scrollY > 100;
      this.isToTopActive = window.pageYOffset > 100;
    },
    removeDropdownStyles() {
      if (window.innerWidth >= 1118) {
        this.dropdownItems.forEach((item) => {
          item.showDropdown = false;
          item.height = "0px";
        });
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    initializeSwiper() {
      new Swiper(".mySwiper", {
        spaceBetween: 30,
        loop: true,
        centeredSlides: true,
        fadeEffect: { crossFade: true },
        grabCursor: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      });
    },
  },
  mounted() {
    if (this.user) {
    //  this.$router.push({ path: "/wizard/select-category" });
    }
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.removeDropdownStyles);
    this.initializeSwiper();
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.removeDropdownStyles);
  },
};
</script>

<!-- <style scoped>
@import "../../../public/assets/css/swiper-bundle.min.css";
</style>
<style scoped>
@import "../../../public/assets/css/all.min.css";
</style>
<style scoped>
@import "../../../public/assets/css/animate.css";
</style>
<style scoped>
@import "../../../public/assets/css/swiper-bundle.min.css";
</style>
<style scoped>
@import "../../../public/assets/css//hover-min.min.css";
</style>
<style scoped>
@import "../../../public/assets/css/remixicon.css";
</style> -->
<style scoped>
@import "../../../public/assets/css/style.css";
</style>
<style scoped></style>
