<template>
  <div>
    <div class="title">
      <h4>{{ $t("Listapro work") }}</h4>
      <p>{{ $t("SideBarSubTitle") }}</p>
    </div>
    <ol class="overflow-hidden position-relative">
      <StepperItem
        v-for="(step, index) in steps"
        :key="index"
        :is-active="currentStep == index"
        :is-done="currentStep - 1 < index"
        :step-number="index + 1"
        :title="step.title"
        :class="[{ clickable: isStepClickable(index) }]"
        @click="goToStep(index)"
      ></StepperItem>
    </ol>
  </div>
</template>

<script>
import StepperItem from "./StepperItem.vue";
import { mapState } from "vuex";

export default {
  name: "StepperComp",
  components: {
    StepperItem,
  },
  computed: {
    localizedSteps() {
      return [
        {
          title: this.$t("Select Category"),
          description: this.$t("Finished"),
          done: false,
          route: "/wizard/select-category",
        },
        {
          title: this.$t("Data Source"),
          done: false,
          route: "/wizard/upload-images",
        },
        { title: this.$t("Review"), done: false, route: "/wizard/review" },
        {
          title: this.$t("Listing"),
          done: false,
          route: "/wizard/listing",
        },
      ];
    },
    ...mapState(["currentStep"]),
    isStepClickable() {
      return (index) => {
        return this.currentStep >= index;
      };
    },
  },
  data() {
    return {
      timelineHeight: 0,
      steps: [],
    };
  },
  watch: {
    "$i18n.locale"() {
      this.updateSteps();
    },
  },
  methods: {
    updateSteps() {
      this.steps = this.localizedSteps;
    },
    goToStep(index) {
      if (this.isStepClickable(index)) {
        this.$router.push(this.steps[index].route);
      }
    },
  },

  mounted() {
    this.updateSteps();
  },
};
</script>

<style scoped lang="scss">
.title {
  margin-bottom: 12px;
  padding-bottom: 16px;
  border-bottom: $main-border-1px;
  h4 {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: #000000e0;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #000000a6;
    width: 95%;
  }
}
@media (max-width: 640px) {
  .title {
    display: none;
  }
}
ol {
  z-index: 2;
  padding-left: 0;
  position: relative;
  height: fit-content;

  span {
    content: "";
    position: absolute;
    height: 75%;
    width: 2px;
    left: 25px;
    top: 0;
    transition: height 0.3s ease; /* Add transition effect for smooth height change */

    &.timeline {
      z-index: -1;
      background: $primary-color;
    }
    &.timeline-way {
      z-index: -1;
      background: #0000000f;
    }
  }
}
@media (max-width: 560px) {
  ol {
    li {
      margin-bottom: 20px;
    }
  }
}
.clickable {
  cursor: pointer;
}
</style>
