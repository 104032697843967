<template>
  <div class="sidebar">
    <div class="h-100 d-flex flex-column">
      <ul>
        <li>
          <button
            @click="onActiveFilters('all')"
            :class="active == 'all' ? 'active' : ''"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.8335 2.33337H13.4168V3.50004H5.8335V2.33337Z"
                fill="#000"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.8335 6.41675H13.4168V7.58342H5.8335V6.41675Z"
                fill="#000"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.8335 10.5H13.4168V11.6667H5.8335V10.5Z"
                fill="#000"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.62516 3.50004C2.14191 3.50004 1.75016 3.89179 1.75016 4.37504C1.75016 4.85829 2.14191 5.25004 2.62516 5.25004C3.10841 5.25004 3.50016 4.85829 3.50016 4.37504C3.50016 3.89179 3.10841 3.50004 2.62516 3.50004ZM0.583496 4.37504C0.583496 3.24746 1.49758 2.33337 2.62516 2.33337C3.75274 2.33337 4.66683 3.24746 4.66683 4.37504C4.66683 5.50262 3.75274 6.41671 2.62516 6.41671C1.49758 6.41671 0.583496 5.50262 0.583496 4.37504Z"
                fill="#000"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.62516 8.75004C2.14191 8.75004 1.75016 9.14179 1.75016 9.62504C1.75016 10.1083 2.14191 10.5 2.62516 10.5C3.10841 10.5 3.50016 10.1083 3.50016 9.62504C3.50016 9.14179 3.10841 8.75004 2.62516 8.75004ZM0.583496 9.62504C0.583496 8.49746 1.49758 7.58337 2.62516 7.58337C3.75274 7.58337 4.66683 8.49746 4.66683 9.62504C4.66683 10.7526 3.75274 11.6667 2.62516 11.6667C1.49758 11.6667 0.583496 10.7526 0.583496 9.62504Z"
                fill="#000"
              />
            </svg>
            <span>All History</span>
          </button>
        </li>
        <li>
          <button
            @click="onActiveFilters('COMPLETE')"
            :class="active == 'COMPLETE' ? 'active' : ''"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.49853 9.25793L12.4258 2.49915L13.2405 3.3342L6.31326 10.093C6.30562 10.1004 6.29799 10.1079 6.29037 10.1153C6.11133 10.2901 5.93757 10.4597 5.77554 10.58C5.59301 10.7156 5.35696 10.8422 5.05463 10.8367C4.75229 10.8311 4.52104 10.696 4.34359 10.5538C4.18607 10.4276 4.01862 10.2518 3.8461 10.0706C3.83876 10.0629 3.8314 10.0552 3.82404 10.0474L0.744141 6.81421L1.58888 6.00953L4.66878 9.24275C4.87198 9.45606 4.9846 9.57247 5.07303 9.64331C5.07418 9.64423 5.0753 9.64513 5.07641 9.64601C5.07755 9.64517 5.07872 9.64431 5.0799 9.64344C5.17086 9.57587 5.28766 9.46367 5.49853 9.25793Z"
                fill="black"
              />
            </svg>
            <span>Complete</span>
          </button>
        </li>
        <li>
          <button
            @click="onActiveFilters('LISTING')"
            :class="active == 'LISTING' ? 'active' : ''"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              style="width: 16px; height: 16px"
            >
              <title>format-list-bulleted</title>
              <path
                d="M7,5H21V7H7V5M7,13V11H21V13H7M4,4.5A1.5,1.5 0 0,1 5.5,6A1.5,1.5 0 0,1 4,7.5A1.5,1.5 0 0,1 2.5,6A1.5,1.5 0 0,1 4,4.5M4,10.5A1.5,1.5 0 0,1 5.5,12A1.5,1.5 0 0,1 4,13.5A1.5,1.5 0 0,1 2.5,12A1.5,1.5 0 0,1 4,10.5M7,19V17H21V19H7M4,16.5A1.5,1.5 0 0,1 5.5,18A1.5,1.5 0 0,1 4,19.5A1.5,1.5 0 0,1 2.5,18A1.5,1.5 0 0,1 4,16.5Z"
              />
            </svg>
            <span>Listing</span>
          </button>
        </li>
        <li>
          <button
            @click="onActiveFilters('GENERATED')"
            :class="active == 'GENERATED' ? 'active' : ''"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              style="width: 16px; height: 16px"
            >
              <title>developer-board</title>
              <path
                d="M22,9V7H20V5A2,2 0 0,0 18,3H4A2,2 0 0,0 2,5V19A2,2 0 0,0 4,21H18A2,2 0 0,0 20,19V17H22V15H20V13H22V11H20V9H22M18,19H4V5H18V19M6,13H11V17H6V13M12,7H16V10H12V7M6,7H11V12H6V7M12,11H16V17H12V11Z"
              />
            </svg>
            <span>Generated</span>
          </button>
        </li>

        <li>
          <button
            @click="onActiveFilters('PROCESSING')"
            :class="active == 'PROCESSING' ? 'active' : ''"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_3069_2387)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.17815 7.71303C2.22029 8.05676 2.34399 8.51591 2.53343 9.21053L3.01071 10.9605C3.12715 11.3875 3.20202 11.6584 3.28173 11.8558C3.35562 12.0388 3.41078 12.0974 3.45387 12.1303C3.49695 12.1632 3.56803 12.201 3.76401 12.2242C3.97539 12.2491 4.25649 12.2501 4.69904 12.2501H9.30133C9.74388 12.2501 10.025 12.2491 10.2364 12.2242C10.4323 12.201 10.5034 12.1632 10.5465 12.1303C10.5896 12.0974 10.6447 12.0388 10.7186 11.8558C10.7983 11.6584 10.8732 11.3875 10.9897 10.9605L11.4669 9.21053C11.6564 8.51591 11.7801 8.05676 11.8222 7.71303C11.8625 7.38412 11.8109 7.28338 11.7696 7.22933C11.7284 7.17529 11.6448 7.09897 11.3168 7.05133C10.9741 7.00154 10.4986 7.00008 9.7786 7.00008L4.22177 7.00008C3.50178 7.00008 3.02625 7.00154 2.68355 7.05133C2.35562 7.09897 2.27201 7.17529 2.23073 7.22933C2.18945 7.28338 2.13783 7.38412 2.17815 7.71303ZM1.02015 7.85499C0.963456 7.39252 0.994648 6.92564 1.30357 6.52117C1.6125 6.11671 2.05473 5.96377 2.51582 5.89679C2.95245 5.83335 3.51384 5.83338 4.17744 5.83341L9.82293 5.83341C10.4865 5.83338 11.0479 5.83335 11.4845 5.89679C11.9456 5.96377 12.3879 6.11671 12.6968 6.52117C13.0057 6.92564 13.0369 7.39252 12.9802 7.85499C12.9265 8.29293 12.7788 8.83454 12.6042 9.47475L12.1068 11.2983C12.0013 11.6854 11.909 12.0238 11.8004 12.2927C11.6832 12.583 11.5252 12.8509 11.2547 13.0575C10.9842 13.2641 10.6842 13.3461 10.3732 13.3828C10.0853 13.4168 9.7345 13.4168 9.33327 13.4167H4.6671C4.26588 13.4168 3.91512 13.4168 3.62716 13.3828C3.31619 13.3461 3.0162 13.2641 2.74571 13.0575C2.47522 12.8509 2.31721 12.583 2.19995 12.2927C2.09137 12.0238 1.9991 11.6854 1.89355 11.2983C1.89076 11.2881 1.88796 11.2778 1.88515 11.2675L1.40788 9.5175C1.40397 9.5032 1.40008 9.48894 1.39621 9.47473C1.22158 8.83453 1.07384 8.29293 1.02015 7.85499Z"
                  fill="black"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.99509 2.91675C4.00911 2.91676 4.02333 2.91676 4.03777 2.91676H9.96303C9.97747 2.91676 9.99169 2.91676 10.0057 2.91675C10.2701 2.91669 10.4598 2.91665 10.6276 2.93916C11.7823 3.09408 12.6793 4.01718 12.8289 5.17535L11.6719 5.32483C11.5881 4.67667 11.0903 4.17836 10.4724 4.09547C10.3924 4.08473 10.2877 4.08343 9.96303 4.08343H4.03777C3.71313 4.08343 3.60836 4.08473 3.52835 4.09547C2.9105 4.17836 2.41266 4.67667 2.32893 5.32483L1.17188 5.17535C1.3215 4.01718 2.21851 3.09408 3.37322 2.93916C3.54102 2.91665 3.73073 2.91669 3.99509 2.91675Z"
                  fill="black"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.79346 2.11331C2.17295 1.18171 3.16861 0.583374 4.26001 0.583374H9.7407C10.8321 0.583374 11.8278 1.18171 12.2073 2.11331L11.1268 2.55344C10.9534 2.1277 10.4339 1.75004 9.7407 1.75004H4.26001C3.56677 1.75004 3.04735 2.1277 2.87392 2.55344L1.79346 2.11331Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_3069_2387">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span>In Progress</span>
          </button>
        </li>
        <li>
          <button
            @click="onActiveFilters('DRAFT')"
            :class="active == 'DRAFT' ? 'active' : ''"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
              width="14"
              height="14"
            >
              <!-- !Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
              <path
                d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z"
              />
            </svg>
            <span>Draft</span>
          </button>
        </li>
        <li>
          <button
            @click="onActiveFilters('FAILED')"
            :class="active == 'FAILED' ? 'active' : ''"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_3069_542)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.23757 9.06241L4.93774 5.76258L5.7627 4.93762L9.06253 8.23745L8.23757 9.06241Z"
                  fill="black"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.93771 8.23745L8.23754 4.93762L9.0625 5.76257L5.76267 9.0624L4.93771 8.23745Z"
                  fill="black"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.00016 1.75004C4.10067 1.75004 1.75016 4.10055 1.75016 7.00004C1.75016 9.89954 4.10067 12.25 7.00016 12.25C9.89966 12.25 12.2502 9.89954 12.2502 7.00004C12.2502 4.10055 9.89966 1.75004 7.00016 1.75004ZM0.583496 7.00004C0.583496 3.45621 3.45634 0.583374 7.00016 0.583374C10.544 0.583374 13.4168 3.45621 13.4168 7.00004C13.4168 10.5439 10.544 13.4167 7.00016 13.4167C3.45634 13.4167 0.583496 10.5439 0.583496 7.00004Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_3069_542">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span>Failed</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "historySidebar",
  data() {
    return {
      active: "all",
    };
  },
  components: {},
  methods: {
    onActiveFilters(filter) {
      this.active = filter;
      this.$store.dispatch("filterHistory", filter);
    },
  },
};
</script>

<style scoped lang="scss">
.sidebar {
  width: 264px;
  height: calc(100vh - 64px);
  padding: 32px 24px 24px 24px;
  border-right: 1px solid
    var(--Colors-Neutral-Border-colorBorderSecondary, #f0f0f0);
  transition: 0.3s;
  @media (max-width: 639.99px) {
    & {
      width: 93px;
    }
  }
  ul {
    padding: 0;
    list-style-type: none;
    li {
      margin-bottom: 10px;
      button {
        background: transparent;
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 9px 24px;
        border-radius: $main-border-radius;
        width: 100%;
        &.active {
          background: $primary-color;
          color: white;
          svg path {
            fill: #e6f4ff;
          }
        }
      }
    }
  }
  @media (max-width: 639.99px) {
    ul {
      li {
        margin-bottom: 16px;
        button {
          padding: 15px;
          span {
            display: none;
          }
        }
      }
    }
  }
}
</style>
