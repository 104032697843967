<template>
  <div class="text-field mb-6" :class="{ error: valueLength === 0 }">
    <div>
      <label
        :for="name"
        class="d-flex justify-content-start align-items-center gap-1"
      >
        <span> {{ name }}</span>
      </label>
      <input
        :id="name"
        type="text"
        v-model="this.informations.value"
        @input="handleInput"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "TextFieldComp",
  data() {
    return {
      name: "",
      value: "",
    };
  },
  props: {
    informations: Object,
  },
  computed: {},
  mounted() {
    this.name = this.informations.name || "";
    this.value = this.informations.value || "";
  },
  methods: {
    handleInput(event) {
      this.$emit("inputEmit", event.target.value);
    },
  },
};
</script>

<style scoped lang="scss">
.text-field {
  label {
    margin-bottom: 8px;
  }
  input {
    padding: 5px 12px;
    border-radius: 6px;
    border: 1px solid #00000026;
    outline: none;
    width: 100%;
    font-weight: 600;
  }
}
.error {
  input {
    border: 1px solid red;
    padding: 5px 12px;
    border-radius: 6px;
    outline: none;
    width: 100%;
  }
}
</style>
