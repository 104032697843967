<template>
  <li class="position-relative d-flex">
    <a class="d-flex align-items-center w-100 pointer-events-none icon">
      <span
        :class="[
          circleClasses,
          {
            done: !isActive && !isDone,
            active: isActive,
            toNext: !isActive && isDone,
          },
        ]"
      >
        {{ stepNumber }}
      </span>
      <div class="d-none d-sm-block text">
        <h4 :class="{ black: isActive || !isDone }">{{ title }}</h4>
      </div>
    </a>
  </li>
</template>

<script>
export default {
  props: {
    isActive: Boolean,
    isDone: Boolean,
    stepNumber: Number,
    title: String,
  },
  computed: {
    circleClasses() {
      return "rounded-pill d-flex justify-content-center align-items-center";
    },
  },
};
</script>

<style scoped lang="scss">
.icon {
  margin-bottom: 12px;
  & > span {
    padding: 8px 15px;
    margin-right: 16px;
    background: white;
    transition: 0.5s all;
    border: solid white 6px;
    &.active,
    &.done {
      background: $primary-color;
      color: white;
    }
    &.toNext {
      color: var(--Components-Steps-Global-colorTextDescription, #00000073);
      background: var(--Components-Steps-Global-colorFillContent, #ececec);
    }
  }
}

.text {
  margin-top: 4px;
  color: #00000073;
  h4 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}
.black {
  color: #000000e0;
  &:hover {
    color: $primary-color;
  }
}
</style>
