import { createRouter, createWebHistory } from "vue-router";
import AppContainer from "@/views/layouts/AppContainer.vue";
import index from "@/views/pages/index.vue";
import MyProfile from "@/components/setting/MyProfile.vue";
import i18n from "../plugins/i18n";
const routes = [
  {
    path: "/",
    component: AppContainer,
    children: [
      {
        path: "setting",
        name: "setting",
        component: () =>
          import(/* webpackChunkName: "wizard" */ "@/views/pages/setting.vue"),
        meta: { requiresAuth: true },
        children: [
          { path: "my-profile", name: "My Profile", component: MyProfile },
          {
            path: "/my-shop",
            name: i18n.global.t("My Shop Integration"),
            component: () =>
              import(
                /* webpackChunkName: "wizard" */ "@/components/setting/MyShopIntegration.vue"
              ),
          },
          {
            path: "/shop-details/:shopName",
            name: "My Shop Details",
            component: () =>
              import(
                /* webpackChunkName: "wizard" */ "@/components/setting/ShopDetails.vue"
              ),
          },
        ],
      },
      {
        path: "history",
        name: "My History",
        component: () =>
          import(/* webpackChunkName: "wizard" */ "@/views/pages/history.vue"),
      },
      {
        path: "/edit-product/:id",
        name: "Update Product",
        component: () =>
          import(
            /* webpackChunkName: "wizard" */ "@/components/Review/editProduct.vue"
          ),

        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/wizard",
    component: AppContainer,
    children: [
      {
        path: "select-category",
        name: "Select Category",
        component: () =>
          import(/* webpackChunkName: "wizard" */ "../views/pages/home.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "upload-images",
        name: "Upload Images",
        component: () =>
          import(
            /* webpackChunkName: "wizard" */ "@/components/DataSource.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "listing",
        name: "Listing",
        component: () =>
          import(/* webpackChunkName: "wizard" */ "@/views/pages/listing.vue"),
      },
      {
        path: "review",
        name: "Review",
        component: () =>
          import(/* webpackChunkName: "wizard" */ "@/views/pages/review.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "review/edit/:id",
        name: "Edit Product",
        component: () =>
          import(
            /* webpackChunkName: "wizard" */ "../components/Review/editProduct.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "review/preview/:id",
        name: "Preview Product",
        component: () =>
          import(
            /* webpackChunkName: "wizard" */ "../components/Review/Preview.vue"
          ),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "wizard" */ "../views/pages/login.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "wizard" */ "../views/pages/register.vue"),
  },
  {
    path: "/review-product/:id",
    name: "Review Product",
    component: () =>
      import(
        /* webpackChunkName: "wizard" */ "@/views/pages/review-product.vue"
      ),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "",
    name: "",
    component: index,
  },
  {
    path: "/shopify_auth",
    name: "shopify_auth",
    component: () =>
      import(
        /* webpackChunkName: "wizard" */ "../views/pages/shopify_auth.vue"
      ),
    meta: { requiresAuth: false },
  },
  {
    path: "/shopify_callback",
    name: "auth_callback",
    component: () =>
      import(
        /* webpackChunkName: "wizard" */ "../views/pages/auth_callback.vue"
      ),
    meta: { requiresAuth: false },
  },
  {
    path: "/frogot_password",
    name: "Forget Password",
    component: () => import("@/components/ForgotPassword/EnterEmail.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/check_your_email",
    name: "Check Your Email",
    component: () => import("@/components/ForgotPassword/CheckYourEmail.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/password/reset",
    name: "Set New Password",
    component: () => import("@/components/ForgotPassword/SetNewPassword.vue"),
    meta: { requiresAuth: false },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  // Check if the route requires authentication
  const auth = localStorage.getItem("accessToken");
  // add name of page to the title of tap
  document.title = to.name ? `${to.name} - Listapro` : "Listapro";

  // if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
  if (to.meta.requiresAuth && !auth) {
    next({ name: "login" });
  } else {
    next();
  }
});
export default router;
