// src/i18n.js
import { createI18n } from "vue-i18n";

// Import your language files
import en from "../locales/en.json";
import ar from "../locales/ar.json"; // Example for French
import de from "../locales/de.json"; // Example for French
import fr from "../locales/fr.json"; // Example for French

const messages = {
  en,
  ar,
  de,
  fr,
};

const i18n = createI18n({
  locale: localStorage.getItem("locale", "en"), // Set default locale
  fallbackLocale: "en", // Set fallback locale
  messages,
});

export default i18n;
