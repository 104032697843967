<template>
  <div class="my-profile">
    <div
      class="settinga-header d-flex justify-content-between align-items-center"
    >
      <div class="description">
        <h1>{{ $t("my profile") }}</h1>
      </div>
    </div>
    <div class="personal-inforamtion">
      <h2>{{ $t("Personal Inforamtion") }}</h2>
      <form class="" @submit.prevent="">
        <div class="row">
          <div class="full-name text-field col-md-6 col-sm-12 form-group">
            <label class="d-flex gap-1 align-items-center" for="full-name">
              <svg
                width="5"
                height="6"
                viewBox="0 0 5 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.83691 5.05078L1.91211 3.30078L0.462891 4.2168L-0.00195312 3.49219L1.58398 2.65137L-0.00195312 1.81738L0.462891 1.09277L1.91211 2.00879L1.83691 0.258789H2.77344L2.69824 2.00195L4.14062 1.09277L4.60547 1.81738L3.01953 2.65137L4.60547 3.49219L4.14062 4.2168L2.69824 3.30762L2.77344 5.05078H1.83691Z"
                  fill="#FF4D4F"
                />
              </svg>
              <span> {{ $t("Full Name") }}</span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM8 13.8125C4.79063 13.8125 2.1875 11.2094 2.1875 8C2.1875 4.79063 4.79063 2.1875 8 2.1875C11.2094 2.1875 13.8125 4.79063 13.8125 8C13.8125 11.2094 11.2094 13.8125 8 13.8125Z"
                  fill="black"
                  fill-opacity="0.45"
                />
                <path
                  d="M9.74375 4.94844C9.275 4.5375 8.65625 4.3125 8 4.3125C7.34375 4.3125 6.725 4.53906 6.25625 4.94844C5.76875 5.375 5.5 5.94844 5.5 6.5625V6.68125C5.5 6.75 5.55625 6.80625 5.625 6.80625H6.375C6.44375 6.80625 6.5 6.75 6.5 6.68125V6.5625C6.5 5.87344 7.17344 5.3125 8 5.3125C8.82656 5.3125 9.5 5.87344 9.5 6.5625C9.5 7.04844 9.15625 7.49375 8.62344 7.69844C8.29219 7.825 8.01094 8.04688 7.80938 8.3375C7.60469 8.63437 7.49844 8.99063 7.49844 9.35156V9.6875C7.49844 9.75625 7.55469 9.8125 7.62344 9.8125H8.37344C8.44219 9.8125 8.49844 9.75625 8.49844 9.6875V9.33281C8.49925 9.18113 8.54574 9.03321 8.63187 8.90834C8.71799 8.78347 8.83975 8.68746 8.98125 8.63281C9.90313 8.27813 10.4984 7.46563 10.4984 6.5625C10.5 5.94844 10.2313 5.375 9.74375 4.94844ZM7.375 11.4375C7.375 11.6033 7.44085 11.7622 7.55806 11.8794C7.67527 11.9967 7.83424 12.0625 8 12.0625C8.16576 12.0625 8.32473 11.9967 8.44194 11.8794C8.55915 11.7622 8.625 11.6033 8.625 11.4375C8.625 11.2717 8.55915 11.1128 8.44194 10.9956C8.32473 10.8783 8.16576 10.8125 8 10.8125C7.83424 10.8125 7.67527 10.8783 7.55806 10.9956C7.44085 11.1128 7.375 11.2717 7.375 11.4375Z"
                  fill="black"
                  fill-opacity="0.45"
                />
              </svg>
            </label>
            <input type="text" id="full-name" v-model="fullName" />
          </div>
          <div class="email text-field col-md-6 col-sm-12 form-group">
            <label class="d-flex gap-1 align-items-center" for="email">
              <span>{{ $t("Email") }}</span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM8 13.8125C4.79063 13.8125 2.1875 11.2094 2.1875 8C2.1875 4.79063 4.79063 2.1875 8 2.1875C11.2094 2.1875 13.8125 4.79063 13.8125 8C13.8125 11.2094 11.2094 13.8125 8 13.8125Z"
                  fill="black"
                  fill-opacity="0.45"
                />
                <path
                  d="M9.74375 4.94844C9.275 4.5375 8.65625 4.3125 8 4.3125C7.34375 4.3125 6.725 4.53906 6.25625 4.94844C5.76875 5.375 5.5 5.94844 5.5 6.5625V6.68125C5.5 6.75 5.55625 6.80625 5.625 6.80625H6.375C6.44375 6.80625 6.5 6.75 6.5 6.68125V6.5625C6.5 5.87344 7.17344 5.3125 8 5.3125C8.82656 5.3125 9.5 5.87344 9.5 6.5625C9.5 7.04844 9.15625 7.49375 8.62344 7.69844C8.29219 7.825 8.01094 8.04688 7.80938 8.3375C7.60469 8.63437 7.49844 8.99063 7.49844 9.35156V9.6875C7.49844 9.75625 7.55469 9.8125 7.62344 9.8125H8.37344C8.44219 9.8125 8.49844 9.75625 8.49844 9.6875V9.33281C8.49925 9.18113 8.54574 9.03321 8.63187 8.90834C8.71799 8.78347 8.83975 8.68746 8.98125 8.63281C9.90313 8.27813 10.4984 7.46563 10.4984 6.5625C10.5 5.94844 10.2313 5.375 9.74375 4.94844ZM7.375 11.4375C7.375 11.6033 7.44085 11.7622 7.55806 11.8794C7.67527 11.9967 7.83424 12.0625 8 12.0625C8.16576 12.0625 8.32473 11.9967 8.44194 11.8794C8.55915 11.7622 8.625 11.6033 8.625 11.4375C8.625 11.2717 8.55915 11.1128 8.44194 10.9956C8.32473 10.8783 8.16576 10.8125 8 10.8125C7.83424 10.8125 7.67527 10.8783 7.55806 10.9956C7.44085 11.1128 7.375 11.2717 7.375 11.4375Z"
                  fill="black"
                  fill-opacity="0.45"
                />
              </svg>
            </label>
            <input type="text" id="email" v-model="email" disabled />
          </div>
        </div>
        <div class="new-password">
          <label>
            <input type="checkbox" v-model="createPassword" />
            {{ $t("Create New Password") }}
          </label>

          <transition name="fade">
            <div v-if="createPassword" class="password-container row">
              <div class="text-field col-md-12 col-sm-12 form-group" style="margin-bottom: unset !important;">
                <div class="text-field col-md-6 col-sm-12 form-group">
                  <label for="oldPassword">Old Password</label>
                  <input type="password" id="oldPassword" v-model="oldPassword" />
                </div>

              </div>
              <div class="text-field col-md-12 col-sm-12 form-group" style="margin-bottom: unset !important;">
              <div class="text-field col-md-6 col-sm-12 form-group">
                <label for="password">{{ $t("Password") }}</label>
                <input type="password" id="password" v-model="password" />
              </div>
              </div>
              <div class="text-field col-md-12 col-sm-12 form-group" style="margin-bottom: unset !important;">
              <div class="text-field col-md-6 col-sm-12 form-group">
                <label for="confirmPassword">{{
                  $t("Confirm Password")
                }}</label>
                <input
                  type="password"
                  id="confirmPassword"
                  v-model="confirmPassword"
                />
              </div>
              </div>
              <div v-if="passwordMismatch">
                <span class="password-error">{{
                  $t("Passwords do not match!")
                }}</span>
              </div>
              <div v-if="oldPasswordWrong">
                <span class="password-error">{{
                    $t("The old password does not match the current password.!")
                  }}</span>
              </div>
            </div>
          </transition>
        </div>
        <div class="d-flex justify-content-end mt-3" >
          <v-btn
            class="cursor-pointer save"
            :loading="loading"
            @click="updateProfileData"
            type="submit"
          >
            {{ $t("Save") }}
          </v-btn>
        </div>
      </form>
    </div>
    <v-dialog v-model="isDataSavedDialog" max-width="520">
      <template v-slot:activator="{ props: activatorProps }">
        <v-btn v-bind="activatorProps" class="dialog-btn"></v-btn>
      </template>
      <v-card style="border-radius: 10px; padding: 20px 24px">
        <div class="d-flex align-items-center justify-content-center">
          <div>
            <div class="dialog-icon">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM11.0234 5.71406L7.73281 10.2766C7.68682 10.3408 7.62619 10.3931 7.55595 10.4291C7.48571 10.4652 7.40787 10.4841 7.32891 10.4841C7.24994 10.4841 7.17211 10.4652 7.10186 10.4291C7.03162 10.3931 6.97099 10.3408 6.925 10.2766L4.97656 7.57656C4.91719 7.49375 4.97656 7.37813 5.07812 7.37813H5.81094C5.97031 7.37813 6.12187 7.45469 6.21562 7.58594L7.32812 9.12969L9.78438 5.72344C9.87813 5.59375 10.0281 5.51562 10.1891 5.51562H10.9219C11.0234 5.51562 11.0828 5.63125 11.0234 5.71406Z"
                  fill="#52C41A"
                />
              </svg>
            </div>
            <div class="dialog-success-title">Data Saved Successfully</div>
            <div class="dialog-success-description">
              All changes have been saved successfully.
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NormalBtn from "../ui/NormalBtn.vue";
import {changPassword} from "@/api/profile";
import {updateProduct} from "@/api/products";
export default {
  name: "MyProfile",
  data() {
    return {
      fullName: "",
      email: "",
      createPassword: false,
      password: null,
      oldPassword: null,
      confirmPassword: null,
      shop: {},
      loading:false,
      passwordMismatch:false,
      isDataSavedDialog:false,
      oldPasswordWrong:false
    };
  },
  components: {
    NormalBtn,
  },
  computed: {
    ...mapGetters(["user"]),
    filteredShopData() {
      let shop_str = this.user.shops[0].json_data;
      let shop = JSON.parse(shop_str);
      let data = Object.fromEntries(
        Object.entries(shop).filter(
          ([key, value]) => value !== "" && value !== null
        )
      );
      console.log(data);
      return data;
    },
  },
  mounted() {
    this.fullName = this.user.name;
    this.email = this.user.email;
  },
  methods: {
    updateProfileData() {
      this.oldPasswordWrong=false
      if(this.password !=this.confirmPassword){
        this.passwordMismatch=true
      }else{
        this.passwordMismatch=false
        this.loading=true
        changPassword({'password':this.password,'oldPassword':this.oldPassword,'fullName':this.fullName,'createPassword':this.createPassword})
          .then((data) => {

            this.loading = false;
            this.isDataSavedDialog = true;
            localStorage.setItem("user", JSON.stringify(data.user));

          })
          .catch((error) => {
            this.loading = false;
            this.oldPasswordWrong=true
          });

      }

     },
    formatKey(key) {
      return key
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
  },
};
</script>

<style lang="scss" scoped>
.my-profile {
  .settinga-header {
    border-bottom: 1px solid #0000000f;
    margin-bottom: 24px;
    padding-bottom: 0;
    .description {
      h1 {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        color: #000000e0;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #000000a6;
      }
    }
  }
  .personal-inforamtion {
    background: #00000005;
    padding: 20px;
    border-radius: $main-border-radius;
    h2 {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: #263238;
      margin-bottom: 24px;
    }
    form {
      color: #000000e0;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;

      .text-field {
        margin-bottom: 24px;
        label {
          margin-bottom: 8px;
        }
        input {
          padding: 5px 12px;
          border-radius: 6px;
          border: 1px solid #00000026;
          outline: none;
          width: 100%;
          font-weight: 600;
          background: #ffffff;
        }
      }
      button.save {
        margin-top: 24px;
        padding: 8px 15px;
        color: #fff;
        background: #2c80ff;
        box-shadow: 0px 2px 0px 0px #0591ff1a;
        border-radius: 10px;
      }
    }
  }
  .new-password {
    margin-top: 24px;
  }
  .password-container {
    margin-top: 10px;
  }
  label {
    margin-right: 10px;
  }
  .password-error {
    color: red;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
