<template>
  <div class="review">
    <div class="products-list">
      <div v-if="!isLoading">
        <div class="products row w-100 gap-y-4" v-if="!isLoading">
          <product
            class="col-xl-4 col-lg-6 col-md-12"
            v-for="product in $store.state.dataGenerated"
            :key="product.id"
            :id="product.id"
            :name="product.json_data ? product.json_data : null"
            :url="product.image"
            :product="product"
            @onDeleteProduct="onDeleteProduct(product.id)"
            v-if="hasData"
          />
          <div class="my-table" v-else>
            <table>
              <tr class="">
                <td class="empty-archive" colspan="6">
                  <EmptyArchive/>
                  <h1>No product archive added</h1>
                  <p>
                    No products have been recently uploaded. Please add products
                    or you can view <a href="/history">My Products History</a>
                  </p>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="go-to-listing">
          <button @click="listProduct" v-if="hasData">List Your Product</button>
        </div>
      </div>
      <div class="progress" v-else-if="isLoading">
        <div>
          <div class="spinner-border custom-spinner" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="dialog">
      <v-dialog v-model="dialogVisible" max-width="400" persistent>
        <v-card style="border-radius: 10px" class="p-4">
          <div class="d-flex align-center pa-4">
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 0.5C5.20156 0.5 0.5 5.20156 0.5 11C0.5 16.7984 5.20156 21.5 11 21.5C16.7984 21.5 21.5 16.7984 21.5 11C21.5 5.20156 16.7984 0.5 11 0.5ZM10.25 5.9375C10.25 5.83437 10.3344 5.75 10.4375 5.75H11.5625C11.6656 5.75 11.75 5.83437 11.75 5.9375V12.3125C11.75 12.4156 11.6656 12.5 11.5625 12.5H10.4375C10.3344 12.5 10.25 12.4156 10.25 12.3125V5.9375ZM11 16.25C10.7056 16.244 10.4253 16.1228 10.2192 15.9125C10.0132 15.7022 9.89773 15.4195 9.89773 15.125C9.89773 14.8305 10.0132 14.5478 10.2192 14.3375C10.4253 14.1272 10.7056 14.006 11 14C11.2944 14.006 11.5747 14.1272 11.7808 14.3375C11.9868 14.5478 12.1023 14.8305 12.1023 15.125C12.1023 15.4195 11.9868 15.7022 11.7808 15.9125C11.5747 16.1228 11.2944 16.244 11 16.25Z"
                fill="#FAAD14"
              />
            </svg>
            <div class="ms-2">
              <div class="title">Are you sure?</div>
              <div class="text">You want to dismiss changes you made</div>
            </div>
          </div>
          <template v-slot:actions>
            <div class="buttons mt-3">
              <button class="cancel-dialog" @click="onHideDialog">
                Cancel
              </button>
              <button class="confirm-delete" @click="onConfirmDialog">
                Yes
              </button>
            </div>
          </template>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import ProductDetails from '@/components/Review/editProduct.vue'
import Product from '@/components/Review/Product.vue'
import Preview from '@/components/Review/Preview.vue'
import ProductStatus from '@/components/Review/ProductStatus.vue'
import NormalBtn from '@/components/ui/NormalBtn.vue'
import {
  deleteProduct,
  generate,
  getResponseAi,
  getReviewProduct,
} from '@/api/products'
import { defineAsyncComponent, reactive } from 'vue'
import axios from 'axios'

export default {
  name: 'ReviewPage',
  data () {
    return {
      productsData: [],
      activeProduct: {},
      displayed: 'status',
      activeProductId: null,
      dataGenerated: [],
      modifiedData: false,
      whereWereYou: 'edit',
      ids: [],
      canSumbit: true,
      loading: false,
      productStatusval: null,
      productSelected: null,
      editHeaderFixed: false,
      AiAvailable: true,
      isLoading: false,
      isFiltter: 'all',
      dialogVisible: false,
      hasData: false,
    }
  },
  components: {
    ProductDetails,
    Product,
    Preview,
    ProductStatus,
    NormalBtn,
    EmptyArchive: defineAsyncComponent(() =>
      import('@/components/ui/svgs/EmptyArchive.vue')
    ),
  },
  computed: {
    ...mapGetters(['user']),
  },
  ...mapMutations([
    'setListOfProducts',
    'setIsListOfProductsDisabled',
    'setDataGenerated',
  ]),
  methods: {
    ...mapMutations([
      'setHidePagePath',
      'setHideInfoCard',
      'setHideUserDataCard',
      'setIsInPreview',
      'setCurrentStep',
      'setIsListOfProductsDisabled',
      'setListOfProducts',
      'setDataGenerated',
    ]),
    listProduct () {
      this.$router.push({ path: '/wizard/listing' })
    },
    onDeleteProduct (id) {
      deleteProduct(id)
        .then((response) => {
          this.isLoading = false
          // let dataGeneratedFromLocalStorge = localStorage.getItem("respons-data-after-uplade-images");
          let dataGeneratedFromLocalStorge = this.$store.state.dataGenerated

          if (dataGeneratedFromLocalStorge) {
            for (let i = 0; i < dataGeneratedFromLocalStorge.length; i++) {
              if (dataGeneratedFromLocalStorge[i].id == id) {
                dataGeneratedFromLocalStorge.splice(i, 1)
                break
              }
            }
            // localStorage.setItem(
            //   "respons-data-after-uplade-images",
            //   JSON.stringify(dataGeneratedFromLocalStorge)
            // );
            this.setListOfProducts(dataGeneratedFromLocalStorge)
            this.setDataGenerated(dataGeneratedFromLocalStorge)
          }
        })
        .catch((error) => {
          this.isLoading = false
          console.error('Error deleteing Products:', error)
        })
    },
    checkAIServer () {

      let isDemo = process.env.VUE_APP_DEMO_MODE === 'true'
      if (!isDemo) {
        axios
          .get('https://ai-cp.sellenvo.com/api/ai_server_status')
          .then((response) => {
            this.AiAvailable = response.data.is_available
            if (!response.data.is_available) {
              this.productStatusval = 'success'
            }
          })
          .catch((error) => {
          })
      }
      eles
      {
        this.AiAvailable = false
      }
    },
    filter (type) {
      this.isFiltter = type
      // let dataGeneratedFromLocalStorge = localStorage.getItem("respons-data-after-uplade-images"  );
      // this.dataGenerated = JSON.parse(dataGeneratedFromLocalStorge);

      let dataGeneratedFromLocalStorge = this.$store.state.dataGenerated

      if (type == 'all') {
        return this.dataGenerated
      } else {
        this.dataGenerated = this.dataGenerated.filter((item) => {
          console.log('item.status', item.status)
          return item.status == type
        })
      }
    },
    onHideDialog () {
      this.dialogVisible = false
    },
    onConfirmDialog () {
      this.dialogVisible = false
    },
  },
  mounted () {
    this.checkAIServer()
    this.setHideUserDataCard(true)
    this.setIsInPreview(true)
    this.setHideInfoCard(false)
    this.setCurrentStep(2)
    // this.setHidePagePath(true);
    window.addEventListener('scroll', () => {
      if (window.scrollY > 500) {
        this.editHeaderFixed = true
      } else {
        this.editHeaderFixed = false
      }
    })

    // if (this.user) {
    //   sessionStorage.setItem(
    //     "isHasMenuOfProducts",
    //     JSON.stringify({
    //       status: true,
    //       userId: this.user.id,
    //     })
    //   );
    //   this.setIsListOfProductsDisabled(true);
    // }
    if (this.$store.state.dataGenerated.length) {
      this.hasData = true
    } else {
      this.hasData = false
    }
  },
  unmounted () {
    // this.setHidePagePath(false);
    this.setHideUserDataCard(false)
    this.setIsInPreview(false)
  },
}
</script>

<style scoped lang="scss">
// .prodcut-selected {
//   background: #f5f5f5;
// }
.review {
  // background-color: #f5f5f5;
  color: #000000e0;
  @media (max-width: 1024px) {
    .edit-preview {
      order: 2;
    }
    .products-list {
      order: 1;
    }
  }

  .products-list {
    // max-height: calc(100vh - 175px);
    overflow-y: scroll;
    scrollbar-width: none;
    margin: 24px;
  }

  .product-uploaded-num {
    background: #f3feff;
    border-bottom: 1px solid #4096ff;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: #000000e0;
    width: 100%;
  }
}

.review-header {
  background: #f3feff;
  border-bottom: 1px solid #4096ff;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #000000e0;
}

.products-and-review {
  // max-height: calc(100vh - 124.8px);
  overflow-y: scroll;
  scrollbar-width: none;
}

.product {
  .image {
    min-width: 152px;
    height: 152px;
    border-radius: 6px;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
    }
  }

  .details {
    h5 {
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
    }

    .description {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: #00000073;
    }

    .status {
      box-shadow: 0px 2px 5px 1px #bfbfbf4a;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      padding: 9px 12px;
      height: 40px;
    }
  }
}

.left-side-content {
  height: calc(100vh - 152px);
  overflow-x: scroll;
  scrollbar-width: none;
  padding: 24px 24px 24px 32px;
}

@media (max-width: 1200px) {
  .left-side-content {
    height: auto;
  }
}

.go-to-listing {
  width: 100%;
  text-align: right;
  margin-top: 20px;
  margin-bottom: 40px;

  button {
    padding: 8px 16px;
    border-radius: $main-border-radius;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #fff;
    background-color: #2c80ff;
    box-shadow: 0px 2px 0px 0px #0591ff1a;
    box-shadow: 0px 2px 0px 0px #0591ff1a;
    margin-top: 24px;
  }
}

.my-table {
  border: 1px solid #0000000f;
  border-radius: 6px;
  padding: 16px;
  height: 100%;

  table {
    width: 100%;
    color: #000000e0;

    th,
    td {
      border: solid 0.5px #0000000f;
      height: 54px;
      width: max-content;
      padding: 16px;

      &.name {
        width: 248px;
      }

      &.description {
        width: 247px;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.name-image {
          justify-content: start;
          gap: 16px;
          width: calc(248px - 32px);
        }

        &.actions {
        }
      }
    }

    th {
      background: #00000005;

      &:first-child {
        width: 48px;
      }

      &:last-child {
        width: 178px;
      }
    }

    td {
      .name-image {
        .image {
          width: 40px;
          height: 40px;
          overflow: hidden;
          border-radius: 6px;

          img {
            width: 100%;
            height: auto;
          }
        }

        .name {
          color: #000000a6;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }
      }

      .description {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }

      .complete {
        background: #f6ffed;
        color: #b7eb8f;
        border: 1px solid #b7eb8f;
        border-radius: 4px;
        width: fit-content;
        padding: 1px 8px;
      }

      .in-progress {
        background: #e6fffb;
        color: #13c2c2;
        border: 1px solid #87e8de;
        border-radius: 4px;
        width: fit-content;
        padding: 1px 8px;
      }

      .faild {
        background: #fff1f0;
        color: #f5222d;
        border: 1px solid #ffa39e;
        border-radius: 4px;
        width: fit-content;
        padding: 1px 8px;
      }

      .clothes {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #000000e0;
      }

      .actions {
        button {
          background: transparent;
        }

        .preview {
          border: solid 1px #00000026;
          border-radius: 6px;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          padding: 5px 16px;
        }

        .cancel {
          border: solid 1px #ff4d4f;
          color: #ff4d4f;
          width: 100%;
          border-radius: 6px;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          padding: 5px 16px;
        }
      }

      &.empty-archive {
        text-align: center;
        padding: 100px 0;

        h1 {
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
          text-align: center;
          color: #000000e0;
          margin-top: 16px;
          margin-bottom: 8px;
        }

        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          color: #000000a6;
        }
      }
    }
  }
}
</style>
