<template>
  <div class="carousel">
    <div class="carousel-inner">
      <div
        v-for="(img, index) in images"
        :key="index"
        :class="['carousel-item', { active: index === activeIndex }]"
      >
        <img :src="img" />
      </div>
    </div>
    <button @click="prev" class="arrow left">&#9664;</button>
    <button @click="next" class="arrow right">&#9654;</button>
    <div class="pagination">
      <span
        v-for="(img, index) in images"
        :key="index"
        :class="['dot', { active: index === activeIndex }]"
        @click="goTo(index)"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomCarousel",
  data() {
    return {
      activeIndex: 0,
    };
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  methods: {
    next() {
      this.activeIndex = (this.activeIndex + 1) % this.images.length;
    },
    prev() {
      this.activeIndex =
        (this.activeIndex - 1 + this.images.length) % this.images.length;
    },
    goTo(index) {
      this.activeIndex = index;
    },
  },
};
</script>

<style scoped lang="scss">
.carousel {
  position: relative;
  width: 100%;
}

.carousel-inner {
  display: flex;
  transition: transform 0.5s ease;
}

.carousel-item {
  min-width: 100%;
  height: 500px;
  transition: opacity 0.5s ease;
  opacity: 0;
  position: absolute;
  img {
    width: auto;
    height: 90%;
  }
}

.carousel-item.active {
  opacity: 1;
  position: relative;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.278);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.arrow.left {
  left: 10px;
}

.arrow.right {
  right: 10px;
}

.pagination {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  background-color: #717171;
}
</style>
