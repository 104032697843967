import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/scss/Main.scss";
import VueAxios from "vue-axios";
import axios from "axios";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "vuetify/styles";

// Ensure Vuetify icons are imported
// import '@mdi/font/css/materialdesignicons.css';

// Import Bootstrap and BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import i18n from "./plugins/i18n";
import BootstrapVueNext from "bootstrap-vue-next";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
import Echo from "laravel-echo";

import Pusher from "pusher-js";
window.Echo = new Echo({
  broadcaster: "reverb",
  key: process.env.VUE_APP_REVERB_APP_KEY,
  wsHost: process.env.VUE_APP_REVERB_HOST,
  wsPort: process.env.VUE_APP_REVERB_PORT,
  wssPort: process.env.VUE_APP_REVERB_PORT,
  forceTLS: false,
  debug: true,
  // enabledTransports: ['ws', 'wss'],
});

const vuetify = createVuetify({
  components,
  directives,
});
createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(vuetify)
  .use(BootstrapVueNext)
  .mount("#app");
