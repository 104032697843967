<template>
  <div class="my-collapse">
    <div
      class="collapse-header d-flex align-items-center gap-2 p-3 pl-4"
      @click="toggleCollapse"
    >
      <svg
        v-if="collapsed"
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.9685 6.65558L4.80527 1.84171C4.78917 1.82903 4.76981 1.82115 4.74943 1.81897C4.72905 1.8168 4.70847 1.82042 4.69005 1.82941C4.67164 1.83841 4.65613 1.85241 4.64531 1.86982C4.6345 1.88724 4.62881 1.90734 4.62891 1.92784V2.98468C4.62891 3.05167 4.66035 3.11593 4.71231 3.15694L9.63417 7.00011L4.71231 10.8433C4.65899 10.8843 4.62891 10.9485 4.62891 11.0155V12.0724C4.62891 12.164 4.73418 12.2146 4.80527 12.1585L10.9685 7.34464C11.0209 7.30378 11.0633 7.25151 11.0924 7.19181C11.1216 7.13211 11.1367 7.06654 11.1367 7.00011C11.1367 6.93367 11.1216 6.86811 11.0924 6.80841C11.0633 6.74871 11.0209 6.69644 10.9685 6.65558Z"
          fill="black"
          fill-opacity="0.88"
        />
      </svg>

      <svg
        v-if="!collapsed"
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5861 3.5H11.5607C11.491 3.5 11.4254 3.53418 11.3844 3.59023L7.50017 8.94414L3.61599 3.59023C3.57498 3.53418 3.50935 3.5 3.43963 3.5H2.41424C2.32537 3.5 2.27342 3.60117 2.32537 3.67363L7.14607 10.3195C7.32107 10.5602 7.67927 10.5602 7.85291 10.3195L12.6736 3.67363C12.7269 3.60117 12.675 3.5 12.5861 3.5Z"
          fill="black"
          fill-opacity="0.88"
        />
      </svg>
      <span>{{ title }}</span>
    </div>
    <transition name="fade">
      <div class="collapse-description p-4 pt-0" v-if="!collapsed">
        <p>
          <template v-if="typeof description === 'string'">
            {{ description }}
          </template>
          <template
            v-else-if="
              typeof description === 'object' && !Array.isArray(description)
            "
          >
            {{ description.value }}
          </template>
          <template v-else-if="Array.isArray(description)">
            <span v-for="(item, itemIndex) in description" :key="itemIndex">
              {{ item.value }}
              <span v-if="itemIndex !== description.length - 1">, </span>
            </span>
          </template>
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      collapsed: true,
    };
  },
  props: {
    title: String,
    description: String,
  },
  methods: {
    toggleCollapse() {
      this.collapsed = !this.collapsed;
    },
  },
};
</script>

<style scoped lang="scss">
.my-collapse {
  .collapse-header {
    color: #000000e0;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    cursor: pointer;
  }
  .collapse-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #000000e0;
    text-align: left;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
