<template>
  <div class="app-container position-relative">
    <div>
      <Navbar />
      <div class="d-flex justify-content-start sidebar">
        <div>
          <Sidebar v-if="currentPage == 'stepper'" />
          <hestorySidebar v-else-if="currentPage == 'history'" />
          <settingSidebar v-else-if="currentPage == 'setting'" />
        </div>
        <div class="layout-content">
          <PageTitle v-if="!hidePagePath" />
          <main
            class="row g-3 main-content"
            :style="customeStyleForPreviewAndHistoryPage"
          >
            <div
              class="changed-content mb-4 mb-md-0 mt-0"
              :class="
                !hideInfoCard
                  ? 'col-xl-12 col-lg-12 pe-0 '
                  : 'col-xl-8 col-lg-12 pe-0 pe-xl-3'
              "
            >
              <transition name="fade" mode="out-in">
                <router-view></router-view>
              </transition>
            </div>
            <div
              class="col-xl-4 col-lg-12 col-md-12 col-sm-12 mt-0"
              v-if="hideInfoCard"
            >
              <InfoCard class="ml-2"></InfoCard>
            </div>
          </main>
        </div>
      </div>
    </div>
    <UserData class="position-fixed user-data" />
    <div class="lsit-of-product" v-if="isListOfProductsDisabled">
      <ListOfProduct />
    </div>

    <!-- <div
      v-show="getcategoryModalStatus"
      class="category-modal position-absolute top-0 start-0 w-100 h-100 d-flex pt-5 justify-content-center"
    >
      <div class="container h-auto p-4 rounded-3 w-auto">
        <Categories
          class=""
          :selectedItem="selectedItem"
          @selectItem="onSelectItem"
        />
        <div class="btns mt-3 mb-2">
          <button class="close me-2 rounded-pill" @click="onCloseModal">
            Close
          </button>
          <NormalBtn @click="onSelectCategory">Submit</NormalBtn>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import review from "../pages/review.vue";
import Navbar from "./modules/Navbar";
import Sidebar from "./modules/Sidebar";
import PageTitle from "./modules/PageTitle.vue";
import InfoCard from "@/components/InfoCard.vue";
import UserData from "@/components/sidbar/UserData.vue";
import Categories from "@/components/SelectCategories/Categories.vue";
import NormalBtn from "@/components/ui/NormalBtn.vue";
import ListOfProduct from "@/components/ui/ListOfProduct.vue";
import hestorySidebar from "@/components/history/Sidbar.vue";
import settingSidebar from "@/components/setting/Sidbar.vue";
import { mapGetters, mapMutations } from "vuex";
import { getResponseAi } from "@/api/products";
import { online } from "@/api/profile";
export default {
  name: "AppContainer",
  data() {
    return {
      selectedItem: 0,
      categoryModalStatus: this.$store.state.categoryModalStatus,
    };
  },
  components: {
    review,
    Navbar,
    Sidebar,
    UserData,
    PageTitle,
    InfoCard,
    Categories,
    NormalBtn,
    hestorySidebar,
    settingSidebar,
    ListOfProduct,
  },
  beforeDestroy() {
    // Clear the interval when the component is about to be destroyed
    this.clearInterval();
  },
  mounted() {
    console.log(process.env.VUE_APP_DEMO_MODE)
    if (this.user) {
      this.startInterval();
      window.Echo.channel("requests-channel" + this.user.id).listen(
        ".request-updated",
        (e) => {
          getResponseAi(
            e.message.request_response.request_identifier,
            e.message.request_response.request_status
          )
            .then((response) => {
              let dataProducts = this.dataGenerated;
              for (let i = 0; i < dataProducts.length; i++) {
                if (
                  dataProducts[i].ai_token ==
                  e.message.request_response.request_identifier
                ) {
                  dataProducts[i] = response.result;
                  this.setListOfProducts(dataProducts);
                  this.setDataGenerated(dataProducts);
                  // localStorage.setItem(
                  //   "respons-data-after-uplade-images",
                  //   JSON.stringify(dataProducts)
                  // );
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      );
    } else {
      console.log("User is not defined");
    }
    // let dataGeneratedFromLocalStorge = localStorage.getItem(
    //   "respons-data-after-uplade-images"
    // );
    //
    // if (dataGeneratedFromLocalStorge) {
    //   this.setListOfProducts(JSON.parse(dataGeneratedFromLocalStorge));
    //   this.setDataGenerated(JSON.parse(dataGeneratedFromLocalStorge));
    // }
  },
  computed: {
    ...mapGetters([
      "currentStep",
      "hideInfoCard",
      "isInPreview",
      "hidePagePath",
      "currentPage",
      "user",
      "isListOfProductsDisabled",
      "listOfProducts",
      "dataGenerated",
    ]),
    getcategoryModalStatus() {
      return this.$store.state.categoryModalStatus;
    },
    customeStyleForPreviewAndHistoryPage() {
      let style = {};

      if (!this.currentPage == "history" || this.hidePagePath) {
        style.minHeight = "calc(100vh - 80px)";
      }

      if (this.isInPreview) {
        style.padding = "0";
        style.marginLeft = "0";
      }

      return style;
    },
  },
  methods: {
    ...mapMutations([
      "setListOfProducts",
      "setIsListOfProductsDisabled",
      "setDataGenerated",
    ]),
    checkRoute() {
      const routesArrayHasInfo = [
        "/wizard/select-category",
        "/wizard/upload-images",
        "/wizard/setting",
        "/wizard/edit-product/:id",
      ];
      return routesArrayHasInfo.includes(this.$route.path);
    },
    onSelectItem(index) {
      if (this.selectedItem !== null) {
        this.selectedItem = null;
      }
      this.selectedItem = index;
    },
    onCloseModal() {
      this.$store.commit("toggleCategoryModal");
    },
    onSelectCategory() {
      this.$store.commit("updateCategory", this.selectedItem);
      this.$store.commit("toggleCategoryModal");
    },
    updateIsListOfProductsDisabled() {
      if (this.listOfProducts.length > 0) {
        this.setIsListOfProductsDisabled(this.listOfProducts.length > 0);
      } else {
        this.setIsListOfProductsDisabled(false);
      }
    },

    startInterval() {
      this.triggerMethod();
      this.intervalId = setInterval(() => {
        this.triggerMethod();
      }, 60000); // 60000 ms = 1 minute
    },
    clearInterval() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        offline(this.user.id);
      }
    },
    triggerMethod() {
      // Your method logic here
      online(this.user.id);
    },
  },
  watch: {
    listOfProducts: {
      handler(newValue, oldValue) {
        this.updateIsListOfProductsDisabled();
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-content {
  overflow-y: auto;
  position: relative;
  background: #f9f9f9;
  &::after {
    position: absolute;
    content: "";
    width: 1px;
    height: 100%;
    background-color: #f0f0f0;
    left: 0;
    top: 0;
  }
  .main-content {
    min-height: calc(100% - 80px);
    background: #fff;
    margin: 0px 16px;
    padding: 24px;
    border-top-left-radius: $main-border-radius;
    border-top-right-radius: $main-border-radius;
    border-top: 1px solid #f0f0f0;
    .changed-content {
      padding-left: 0;
      padding-right: 16px;
      //   width: calc(100% - 385px);
      //   @media (max-width: 1280px) {
      //     & {
      //       width: calc(100% - 300px);
      //     }
      //   }
      //   @media (max-width: 1024px) {
      //     & {
      //       width: 100%;
      //     }
      //   }
    }
  }
}
.sidebar {
  position: fixed;
  top: 64px;
  left: 0;
  height: calc(100vh - 64px);
  transition: width 0.3s;
}
.category-modal {
  z-index: 1030;
  background: #09090985;
  .container {
    background: white;
  }
  .category {
    background: white;
  }
  .close {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #4698fd;
    border: 1px solid #4698fd;
    padding: 5px 15px;
  }
}
.user-data {
  z-index: 990;
  bottom: 24px;
  left: 24px;
}
.lsit-of-product {
  position: fixed;
  bottom: 0;
  right: 36px;
  border-radius: 12px 0 0;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px #00230b33;
}
</style>
